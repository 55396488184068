import { SET_SCANKIT_USAGE, RESET_ALL } from "redux/types";

const defaultScankitUsage = {
  projectCount: 0,
  projectPublicCount: 0,
  projectVisitUnit: 0,
  username: "",
};

const scankitUsageReducer = (state = defaultScankitUsage, action) => {
  switch (action.type) {
    case SET_SCANKIT_USAGE:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_ALL:
      return defaultScankitUsage;
    default:
      return state;
  }
};

export default scankitUsageReducer;
