import styled from "styled-components";

export const StyledScanKitNew = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;

  .uploading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    .loadingWrapper {
      width: 410px;

      .loadingBar {
        width: 100%;
        height: 8px;
        background: #e0e0e0;
        border-radius: 12px;
        margin-top: 64px;
        margin-bottom: 24px;
        position: relative;
      }

      .loadingBar::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: ${({ uploadProgress }) => `${uploadProgress}%`};
        height: 100%;
        background: #54c68f;
        border-radius: 12px;
      }
    }
  }

  .fileNamePanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 24px 0px 0px;
    gap: 10px;
    width: 304px;
    height: auto;
    background: #ffe8e1;
    border-radius: 55px;
    margin: 0 auto;

    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #333333;
    }
  }

  .contentContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% - 56px);

    .uploadContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .uploadWrapper {
        width: 628px;

        .dropzone {
          width: 100%;
          background: #ffffff;
          border: 1px dashed #ff744e;
          border-radius: 12px;

          &:hover {
            background: #fff9f7;
          }

          > div {
            width: 100%;
            height: 100%;
            padding: 48px 72px;

            .textWrapper {
              width: 484px;

              .title {
                font-weight: 500;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.02em;
                color: #333333;
                margin-top: 24px;
              }

              .description {
                text-align: left;
                margin-top: 24px;

                > li {
                  display: flex;
                  align-items: start;
                  margin-bottom: 8px;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: 0.02em;
                  color: #666666;

                  > svg {
                    fill: #54c68f;
                    font-size: 20px;
                    margin-right: 7.75px;
                  }

                  > div {
                    max-width: 456px;
                  }

                  > div > span {
                    color: #54c68f;
                  }
                }
              }
            }
          }
        }

        .dragActive {
          background: #fff9f7;
          border: 1px solid #ff744e;
        }

        .buttonPanel {
          width: 100%;
          padding-top: 80px;
          display: flex;
          justify-content: space-between;

          > button {
            padding: 12px 24px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
          }

          .deleteButton {
            background: #f2f2f2;
            border: 1px solid #f2f2f2;
            color: #666666;
          }

          .uploadButton {
            background: #ed0973;
            border: 1px solid #ed0973;
            color: #ffffff;
          }

          .disableButton {
            background: #fab5d5;
            border: 1px solid #fab5d5;
          }
        }
      }
    }

    .editInfoContainer {
      background: #999;
    }
  }
`;
