import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";
import { OPEN_MODAL, CLOSE_MODAL } from "redux/types";

// styles
import { StyledScanKitNew } from "./StyledScanKitNew";

// plugin
import Dropzone from "react-dropzone";

// icons
import { HiExclamationCircle } from "react-icons/hi";
import { ReactComponent as FileUpload } from "./images/Art-Save.svg";

// api
import { uploadFile } from "api/scankit/uploadFile.api";
import { getProject } from "api/scankit/getProject.api";
import { deleteProject } from "api/scankit/deleteProject.api";

// plugin
import Cookies from "js-cookie";

// components
import ScanHeader from "components/ScanHeader/ScanHeader";
import ScanButton from "components/ScanButton/ScanButton";

// i18n
import { useTranslation, Trans } from "react-i18next";

const ScanKitNew = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isReupload = urlParams.get("reupload");

  // useEffect(() => {
  //     getProject(Cookies.get('token'), Cookies.get('scanProjectId')).then((res) => {
  //         // console.log(res);
  //         Cookies.set('scanModelId', res.project.editors[0].models[0].id);
  //     });
  // }, []);

  const [fileData, setFileData] = useState(null);
  const [fileType, setFileType] = useState(null);

  const handleCheckFileType = (acceptedFiles, rejectedFiles) => {
    // console.log(acceptedFiles);
    // console.log(rejectedFiles);

    // 影片上傳容量上限 200MB
    let videoMaxSize = 200 * 1024 * 1024;

    // 圖片上傳容量上限 100MB
    let imagesMaxSize = 1048576 * 100;

    if (rejectedFiles.length > 0) {
      // 有被拒絕的檔案＝上傳錯誤格式檔案(除jpg、png、mp4以外的)
      dispatch({
        type: OPEN_MODAL,
        payload: {
          isOpen: true,
          icon: "oops",
          title: t("modal.incorrectFileFormat"),
          description: t("modal.fileFormatLimitation"),
          button2: t("common.confirm"),
          handleConfirm: () => {
            dispatch({ type: CLOSE_MODAL });
          },
        },
      });
    } else {
      // 沒有被拒絕的檔案＝上傳格式都正確的檔案(jpg、png、mp4)
      if (acceptedFiles[0].type === "video/mp4") {
        // 上傳的資料型態為mp4檔
        setFileType("video");
        if (acceptedFiles[0].size > videoMaxSize) {
          // 超過檔案容量上限，跳出 popup
          dispatch({
            type: OPEN_MODAL,
            payload: {
              isOpen: true,
              icon: "oops",
              title: t("modal.overSizeLimit"),
              description: t("modal.sizeLimitation"),
              description2: t("modal.sizeSuggestion"),
              button2: t("common.confirm"),
              handleConfirm: () => {
                dispatch({ type: CLOSE_MODAL });
              },
            },
          });
          return;
        }
      } else {
        // 上傳的資料型態為jpg、png
        setFileType("images");
        let sumFileSize = 0;
        acceptedFiles.forEach((file) => {
          sumFileSize += file.size;
        });
        if (sumFileSize > imagesMaxSize) {
          // 超過檔案容量上限，跳出 popup
          dispatch({
            type: OPEN_MODAL,
            payload: {
              isOpen: true,
              icon: "oops",
              title: t("modal.overSizeLimit"),
              description: t("modal.sizeLimitation"),
              description2: t("modal.sizeSuggestion"),
              button2: t("common.confirm"),
              handleConfirm: () => {
                dispatch({ type: CLOSE_MODAL });
              },
            },
          });
          return;
        }
      }
      // 將接受的檔案儲存起來
      setFileData(acceptedFiles);
    }
  };

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const handleUploadEvent = () => {
    // console.log(fileData);
    setUploading(true);

    const handleUploadBar = (progress) => {
      if (progress > 96) {
        setUploadProgress(96);
      } else {
        setUploadProgress(progress);
      }
    };

    let formData = new FormData();
    const fileName = fileType === "video" ? "file" : "files";
    fileData.forEach((file) => {
      formData.append(fileName, file);
    });

    uploadFile(
      formData,
      fileType,
      Cookies.get("token"),
      Cookies.get("scanProjectId"),
      Cookies.get("scanModelId"),
      handleUploadBar,
    )
      .then((res) => {
        // console.log(res);
        navigate("/info");
      })
      .catch((err) => {
        dispatch({
          type: OPEN_MODAL,
          payload: {
            isOpen: true,
            icon: "error",
            title: t("errorWhileUploading"),
            description: t("interneterror"),
            button2: t("PleaseTryAgain"),
            handleConfirm: () => {
              // 應該要重新整理
              window.location.reload();
            },
          },
        });
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const handleBackToHomeEvent = () => {
    deleteProject(Cookies.get("token"), Cookies.get("scanProjectId")).then(() => {
      // 回到平台首頁
      navigate("/cardList");
    });
  };

  const handleBackEvent = () => {
    deleteProject(Cookies.get("token"), Cookies.get("scanProjectId")).then(() => {
      // 回到選取上傳類型頁
      navigate("/chooseType");
    });
  };

  return (
    <StyledScanKitNew uploadProgress={uploadProgress}>
      {uploading && (
        <div className="uploading">
          <div className="loadingWrapper">
            <div className={fileData && "fileNamePanel"}>
              <FileUpload />
              {fileData && (
                <Trans i18nKey="scankitNew.totalUpload">
                  共上傳{{ fileLength: fileData.length }}個檔案
                </Trans>
              )}
            </div>
            <div className="loadingBar"></div>
            <p className="title">
              {t("scankitNew.uploading")} {uploadProgress}%
            </p>
          </div>
        </div>
      )}
      <ScanHeader
        title={Boolean(isReupload) ? t("scankitNew.Reupload") : undefined}
        clickEvent={handleBackToHomeEvent}
      />
      <div className="contentContainer">
        <div className="uploadContainer">
          <div className="uploadWrapper">
            <Dropzone
              onDrop={handleCheckFileType}
              accept="image/jpeg, image/png, video/mp4"
              // minSize={0}
              // maxSize={1048576}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <section className={`dropzone ${isDragActive && "dragActive"}`}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className={fileData && "fileNamePanel"}>
                      <FileUpload />
                      {fileData && (
                        <Trans i18nKey="scankitNew.totalUpload">
                          共上傳{{ fileLength: fileData.length }}個檔案
                        </Trans>
                      )}
                    </div>
                    <div className="textWrapper">
                      <p className="title">{t("scankitNew.clickAndDragTheFile")}</p>
                      <ul className="description">
                        <li>
                          <HiExclamationCircle />
                          <div>
                            <Trans i18nKey="scankitNew.imageFormatSupport">
                              圖片格式僅支援<span>JPG、PNG</span>
                            </Trans>
                          </div>
                        </li>
                        <li>
                          <HiExclamationCircle />
                          <div>
                            <Trans i18nKey="scankitNew.theMaxSupport">
                              圖片支援檔案<span>最大容量100MB</span>、圖片張數<span>最多50張</span>
                              <br />
                              (建議容量小於20MB、解析度720p、張數10張以內)
                            </Trans>
                          </div>
                        </li>
                        <li>
                          <HiExclamationCircle />
                          <div>
                            <Trans i18nKey="scankitNew.theRuleOfFileName">
                              圖片檔案命名規則只能使用<span>數字字元</span>並<span>依照順序</span>
                              排列
                              <br />
                              (範例: 001.png、002.png、003.png ...以此類推)
                            </Trans>
                          </div>
                        </li>
                        <li>
                          <HiExclamationCircle />
                          <div>
                            影片格式僅支援<span>MP4</span>
                          </div>
                        </li>
                        <li>
                          <HiExclamationCircle />
                          <div>
                            影片支援<span>最大容量200MB</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
            <div className="buttonPanel">
              <ScanButton type="secondary" text={t("common.return")} clickEvent={handleBackEvent} />
              <ScanButton
                type="primary"
                text={t("uploadObject")}
                clickEvent={handleUploadEvent}
                disabled={!fileData}
              />
            </div>
          </div>
        </div>
      </div>
    </StyledScanKitNew>
  );
};

export default ScanKitNew;
