import React, { useEffect, useRef, useState } from "react";
import CustomGS3DViewer from "../../core/three/CustomGS3DViewer";
import Three from "../../core/three/Three";
import * as GaussianSplats3D from "@mkkellogg/gaussian-splats-3d";

const splatFormats = {
  splat: GaussianSplats3D.SceneFormat.Splat,
  ksplat: GaussianSplats3D.SceneFormat.KSplat,
};

const ThreeJs = React.memo(({ onThumbnailReceived, fileModel, fileType }) => {
  const [fileDataUrl, setFileDataUrl] = useState("");
  const viewerRef = useRef(null);
  const containerRef = useRef(null);
  const initializedRef = useRef(false);

  useEffect(() => {
    if (fileModel) {
      const fileLink = URL.createObjectURL(fileModel);
      setFileDataUrl(fileLink);

      return () => {
        URL.revokeObjectURL(fileLink);
      };
    }
  }, [fileModel]);

  const onWindowResize = () => {
    if (viewerRef.current && containerRef.current) {
      const { clientWidth: width, clientHeight: height } = containerRef.current;
      const viewer = viewerRef.current;
      viewer.renderer.setSize(width, height);
      viewer.camera.aspect = width / height;
      viewer.camera.updateProjectionMatrix();
    }
  };

  useEffect(() => {
    if (!initializedRef.current && fileDataUrl) {
      const three = new Three();
      three.renderer.setClearColor(0xd1d1d1);
      three.init(containerRef.current);
      const viewer = new CustomGS3DViewer({
        renderer: three.renderer,
        cameraUp: [0, 0, 1],
        initialCameraLookAt: [0, 0, 0],
        sharedMemoryForWorkers: false,
      });
      viewerRef.current = viewer;

      viewer
        .addSplatScene(fileDataUrl, {
          splatAlphaRemovalThreshold: 5,
          showLoadingUI: true,
          scale: [5, 5, 5],
          format: splatFormats[fileType],
        })
        .then(() => {
          viewer.start();
          viewer.onThumbnailChange((newThumbnail) => {
            onThumbnailReceived(newThumbnail);
          });
        });

      window.addEventListener("resize", onWindowResize);
      initializedRef.current = true;
    }

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, [fileDataUrl]);

  return (
    <div
      id="threejs"
      ref={containerRef}
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "900px",
        visibility: "hidden",
      }}
    />
  );
});

export default ThreeJs;
