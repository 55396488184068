import React, { useState } from "react";

// styled components
import { StyledSuccessCard } from "./StyledSuccessCard";

const iconSuccess = "./assets/icons/icon-success.svg";

const SuccessCard = (props) => {
  return (
    <StyledSuccessCard mask={props.mask}>
      <div className="whiteBg">
        <div className="loading">
          <div id="loading-wrapper">
            <img src={iconSuccess} alt="" />
          </div>
        </div>
        <div className="title">{props.title}</div>
        <div className="txt">{props.txt}</div>
      </div>
    </StyledSuccessCard>
  );
};

export default SuccessCard;
