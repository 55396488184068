import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// redux
import { SET_SCANKIT_IMAGE_LIST, SET_SCANKIT_TAG_LIST, TOGGLE_SWITCH } from "redux/types";

// styles
import { StyledViewer } from "./StyledViewer";

// components
import Image360 from "components/Image360/Image360";

// api
import { getObjectInfo } from "api/scankit/getObjectInfo.api";

// images
import Image360Logo from "./images/360logo.png";
import { ReactComponent as ZoomIn } from "./images/zoom-in.svg";
import { ReactComponent as ZoomOut } from "./images/zoom-out.svg";

// icons
import { CgEye } from "react-icons/cg";
import { RiEyeCloseLine } from "react-icons/ri";

const ScanKitViewer = () => {
  const dispatch = useDispatch();
  const imageList = useSelector((store) => store.imageList.scanFileList);
  const tags = useSelector((store) => store.tagList.oldTags);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uuid = urlParams.get("uid");
  const [downloading, setDownloading] = useState(false);
  const [viewer, setViewer] = useState(false);

  // 細節開關，預設值為editor編輯值
  const [currentProject, setCurrentProject] = useState({
    scanSwitch: true,
    tagSwitch: true,
  });

  const scaleRate = 0.1; // 縮放倍率
  const scaleLimit = 30; // 點擊次數限制（起始為0需要-1
  const [rate, setRate] = useState(1);
  const [zoomTimes, setZoomTimes] = useState(0);

  const handleZoomInEvent = () => {
    if (zoomTimes > scaleLimit) return;
    setRate(rate + scaleRate);
    setZoomTimes(zoomTimes + 1);
  };

  const handleZoomOutEvent = () => {
    if (zoomTimes < scaleLimit * -1 || rate <= 0.2) return;
    setRate(rate - scaleRate);
    setZoomTimes(zoomTimes - 1);
  };

  const handleMouseWheel = (event) => {
    // 標籤開關開啟及有多個標籤則不執行
    if (currentProject.tagSwitch && tags.length > 0) return;
    // 滾輪垂直距離超過120才觸發乙次縮放
    if (Math.abs(event.deltaY) > 10) {
      // 滾輪往上滾(數值為正)為放大，滾輪往下滾(數值為負)為縮小
      if (event.deltaY > 0) handleZoomInEvent();
      if (event.deltaY < 0) handleZoomOutEvent();
    }
  };

  const handleSwitchDetails = () => {
    setRate(1); // 使用細節功能時，不使用放大縮小功能，故恢復預設值
    setZoomTimes(0);
    setCurrentProject({
      ...currentProject,
      tagSwitch: !currentProject.tagSwitch,
    });
    dispatch({ type: TOGGLE_SWITCH, payload: { tagSwitch: !currentProject.tagSwitch } });
  };

  useEffect(() => {
    if (!uuid) return false;

    setDownloading(true);
    getObjectInfo(uuid)
      .then((res) => {
        setCurrentProject({
          ...currentProject,
          scanSwitch: res.onlineProduct.onlineProductEditors[0].onlineProductModels[0].scanSwitch,
          tagSwitch: res.onlineProduct.onlineProductEditors[0].onlineProductModels[0].tagSwitch,
        });
        dispatch({
          type: SET_SCANKIT_IMAGE_LIST,
          payload: {
            scanFileList:
              res.onlineProduct.onlineProductEditors[0].onlineProductModels[0].scanFileList,
          },
        });
        dispatch({
          type: SET_SCANKIT_TAG_LIST,
          payload: {
            oldTags:
              res.onlineProduct.onlineProductEditors[0].onlineProductModels[0].onlineProductTags,
          },
        });
        dispatch({
          type: TOGGLE_SWITCH,
          payload: {
            scanSwitch: res.onlineProduct.onlineProductEditors[0].onlineProductModels[0].scanSwitch,
            tagSwitch: res.onlineProduct.onlineProductEditors[0].onlineProductModels[0].tagSwitch,
          },
        });
      })
      .finally(() => {
        setDownloading(false);
        setViewer(true);
      });
  }, []);

  return (
    <StyledViewer>
      {tags.length > 0 && (
        <div className="detailsButton">
          <div
            className={currentProject.tagSwitch ? "switchContainer" : "switchContainer inActive"}
            onClick={handleSwitchDetails}
          >
            <div className="switchToggle flexCenter">
              {currentProject.tagSwitch ? <CgEye /> : <RiEyeCloseLine />}
            </div>
          </div>
        </div>
      )}
      <div className="scankitViewerLogo">
        <img src={Image360Logo} alt="360-logo" />
      </div>
      {(!currentProject.tagSwitch || tags.length <= 0) && (
        <div className="actionButtons">
          <div
            onClick={handleZoomInEvent}
            className={zoomTimes > scaleLimit ? "zoomDisabled" : "zoomHover"}
          >
            <ZoomIn className="zoomIcon" />
          </div>
          <div onClick={handleZoomOutEvent} className={rate <= 0.2 ? "zoomDisabled" : "zoomHover"}>
            <ZoomOut className="zoomIcon" />
          </div>
        </div>
      )}
      <div className="contentContainer" onWheel={handleMouseWheel}>
        {downloading ? (
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <Image360
            imageList={imageList}
            viewer={viewer}
            rate={rate}
            tags={tags}
            tagNavbar={false}
          />
        )}
      </div>
    </StyledViewer>
  );
};

export default ScanKitViewer;
