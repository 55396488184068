import styled from "styled-components";

export const StyledSwitch = styled.div`
  width: 30px;
  height: 12px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.38);
  cursor: pointer;
  position: relative;
  .color {
    width: 0;
    height: 100%;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.38);
    position: relative;
    .shape {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #ffffff;
      position: absolute;
      top: -3px;
      left: 0px;
      transition: 0.25s left;
    }
    .shapeMove {
      left: 12px;
      background: #ffa270;
    }
  }
  .colorMove {
    width: 100%;
    text-align: right;
    background: rgba(255, 162, 112, 0.38);
  }
`;
