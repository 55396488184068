// styles
import { StyledButton } from "./StyledButton";

const Button = (props) => {
  const { type, size = "lg", icon, text, clickEvent, disabled = false } = props;

  return (
    <StyledButton type={type} size={size} onClick={clickEvent} disabled={disabled}>
      {icon}
      <span>{text}</span>
    </StyledButton>
  );
};

export default Button;
