import React from "react";
import ReactDOM from "react-dom";

// redux
import { Provider } from "react-redux";
import store from "./redux/store";

// i18n
import i18n from "i18n";

// react query
import { QueryClient, QueryClientProvider, QueryCache } from "react-query";

import App from "./pages/App/App";

const queryCache = new QueryCache();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient} contextSharing={true} queryCache={queryCache}>
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </QueryClientProvider>,
  document.getElementById("root"),
);
