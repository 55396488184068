import axiosApi, { setHeader } from "api/root.api";

export const deleteModel = async (token, projectId, modelId) => {
  const urlEnd = `/editor/project/v1/${projectId}/${modelId}`;
  // setHeader('Access-Control-Allow-Origin', '*');
  setHeader("Authorization", `Bearer ${token}`);
  const response = await axiosApi.delete(urlEnd);
  const result = response.data;
  return result;
};
