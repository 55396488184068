import Cookies from "js-cookie";

export const getOfficialWebsiteLang = () => {
  const lang = Cookies.get("lang");
  const langMapping = {
    en: "en",
    "zh-tw": "zh",
    fr: "fr",
    ko: "ko",
  };
  if (!lang) {
    return langMapping.en;
  }
  return langMapping[lang];
};
