import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";

//react icon
import { FaGlobe } from "react-icons/fa";
import { AiOutlineCheck } from "react-icons/ai";

//image
import bg from "./images/bg.svg";
import logo from "./images/logo.svg";

//styled
import { StyledFinshPassword } from "./styledFinshPassword";

//plugin
import Cookies from "js-cookie";

//i18n
import { useTranslation } from "react-i18next";

//env
import SERVER from "constants/server";

// lang
import { getOfficialWebsiteLang } from "utils/language";

const FinshPassword = () => {
  const { t, i18n } = useTranslation();

  const [langList, setLangList] = useState(false);
  const [currentLang, setCurrentLang] = useState(Cookies.get("lang"));

  const openLangListEvent = () => {
    setLangList(!langList);
  };

  const lanEvent = (lang) => {
    i18n.changeLanguage(lang);
    Cookies.set("lang", lang);
    setLangList(false);
    setCurrentLang(lang);
    window.location.reload();
  };

  return (
    <StyledFinshPassword>
      <div className="whiteBg">
        <a href={`${SERVER.AR_MAKER}/${getOfficialWebsiteLang(currentLang)}`} target="_blank" rel="noopener">
          <img src={logo} className="logo" alt="ARmaker Logo" />
        </a>
        <h1>密碼重設信已經發送至您的電子信箱。</h1>
        <p>
          請使用信件中的密碼登入平台。成功登入平台後，可前往會員設定重新完成新密碼設定。期待很快看到您回來！
        </p>
        <Link to="/login" className="button">
          返回登入
        </Link>
        <div className="langBtn" onClick={openLangListEvent}>
          <FaGlobe className="globa" />
          {currentLang === "en" ? "English" : "繁體中文"}
        </div>
        {langList && (
          <ul className="langList">
            <li
              onClick={() => lanEvent("zh-tw")}
              className={currentLang === "zh-tw" ? "current" : ""}
            >
              繁體中文
              <AiOutlineCheck />
            </li>
            <li onClick={() => lanEvent("en")} className={currentLang === "en" ? "current" : ""}>
              English
              <AiOutlineCheck />
            </li>
          </ul>
        )}
      </div>
      <img src={bg} className="bg" alt="" />
    </StyledFinshPassword>
  );
};

export default FinshPassword;
