// styles
import { StyledScanButton } from "./StyledScanButton";

const ScanButton = ({ type, icon, text, block, clickEvent, disabled }) => {
  return (
    <StyledScanButton block={block} disabled={disabled}>
      <button
        className={disabled ? "button" : `button button-${type}`}
        onClick={clickEvent}
        disabled={disabled}
      >
        {icon}
        <span>{text}</span>
      </button>
    </StyledScanButton>
  );
};

export default ScanButton;
