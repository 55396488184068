import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// styles
import { StyledScanKitInfo } from "./StyledScanKitInfo";

// components
import ScanButton from "components/ScanButton/ScanButton";
import ScanHeader from "components/ScanHeader/ScanHeader";
import Loading from "components/Loading/Loading";
import ModalPopup from "components/ModalPopup/ModalPopup";

// plugin
import Cookies from "js-cookie";
import * as dayjs from "dayjs";

// api
import { getProject } from "api/scankit/getProject.api";
import { updateProject } from "api/scankit/updateProject.api";
import { openProject } from "api/openProject/openProject.api";
import { fetchModelStatus } from "api/scankit/fetchModelStatus";

// react icon
import { HiMenuAlt3 } from "react-icons/hi";
import { FaFolderPlus } from "react-icons/fa";
import { BsArrowRepeat } from "react-icons/bs";

// i18n
import { useTranslation } from "react-i18next";

// hooks
import useModal from "hooks/useModal";

// config
import { modalConfig } from "config/modal";

// lang
import { getOfficialWebsiteLang } from "utils/language";

const ScanKitInfo = () => {
  const { t } = useTranslation();
  const store = useSelector((store) => store);
  const { publicProject: publicProjectLimit } = useSelector((store) => store.permits);
  const { projectPublicCount } = useSelector((store) => store.projectRecord);
  const { modalSetting, setModalSetting } = useModal();

  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isEditInfo = urlParams.get("editInfo");

  const [projectInfo, setProjectInfo] = useState({
    name: "",
    description: "",
    dateCreated: "",
    lastUpdated: "",
    publicSwitch: false,
    lastPublic: "",
    modelThumb: "",
  });
  const [modelInfo, setModelInfo] = useState({
    modelId: "",
    status: "",
  });

  useEffect(() => {
    getProject(Cookies.get("token"), Cookies.get("scanProjectId")).then((res) => {
      // console.log(res);
      let resProject = res.project;
      let resEditor = resProject.editors[0];
      let resModel = resEditor.models[0];
      Cookies.set("scanModelId", resModel.id);
      setProjectInfo({
        ...projectInfo,
        name: resProject.name,
        description: resProject.description,
        dateCreated: dayjs(resProject.dateCreated).format("YYYY/MM/DD"),
        lastUpdated: dayjs(resProject.lastUpdated).format("YYYY/MM/DD"),
        publicSwitch: resEditor.publicSwitch,
        lastPublic:
          resEditor.lastPublic !== null && dayjs(resEditor.lastPublic).format("YYYY/MM/DD"),
        modelThumb: resModel.modelThumb,
      });
      setModelInfo({
        modelId: resModel.id,
        status: resModel.status,
      });
    });
  }, []);

  const changeProjectName = (e) => {
    setProjectInfo({
      ...projectInfo,
      name: e.target.value,
    });
  };

  const changeDescription = (e) => {
    setProjectInfo({
      ...projectInfo,
      description: e.target.value,
    });
  };

  const handleReuploadEvent = () => {
    navigate({
      pathname: "/new",
      search: "?reupload=true",
    });
  };

  const [saveLoading, setSaveLoading] = useState(false);

  const handleSaveEvent = () => {
    let body = {
      id: Cookies.get("scanProjectId"),
      name: projectInfo.name,
      description: projectInfo.description,
    };
    setSaveLoading(true);
    updateProject(body, Cookies.get("token"))
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const handleSaveAndEditEvent = () => {
    handleSaveEvent();
    setTimeout(() => {
      navigate("/editor");
    }, 2000);
  };

  const handleCloseEvent = () => {
    navigate("/cardList");
  };

  const [publicLoading, setPublicLoading] = useState(false);

  const handlePublicEvent = () => {
    if (projectPublicCount >= publicProjectLimit) {
      setModalSetting({
        ...modalSetting,
        show: true,
        type: store.permits.plan.indexOf("free") > -1 ? "type19" : "type40",
        desc: t("publicFail", {
          projectCount: projectPublicCount,
          projectLimit: publicProjectLimit,
        }),
        handleConfirm: () => {
          if (store.permits.plan.indexOf("free") > -1) {
            navigate("/cardList?upgrade=true");
          } else {
            const owLang = getOfficialWebsiteLang();
            window.open(`${SERVER.AR_MAKER}/${owLang}/company/contact_us?skipIntroVideo=true`);
          }
        },
      });
    } else {
      setPublicLoading(true);
      openProject(Cookies.get("scanProjectId"), Cookies.get("token")).then(() => {
        setProjectInfo({
          ...projectInfo,
          publicSwitch: true,
        });
        setPublicLoading(false);
      });
    }
  };

  // 輪詢功能
  useEffect(() => {
    // model 處理狀態為「已完成」或「失敗」則不繼續輪詢
    if (modelInfo.status === "DONE" || modelInfo.status === "PENDING") return;

    // 每 60 秒執行一次「查詢模型狀態」請求
    const intervalId = setInterval(() => {
      fetchModelStatus([modelInfo.modelId]).then((res) => {
        setModelInfo({
          ...modelInfo,
          status: res[modelInfo.modelId],
        });
      });
    }, 60000);

    return () => clearInterval(intervalId);
  }, [modelInfo.status]);

  return (
    <StyledScanKitInfo>
      {publicLoading && (
        <Loading mask="true" title={t("publishingProject")} txt={t("pleaseWait")} />
      )}
      {modalSetting.show && (
        <ModalPopup
          modalConfig={modalConfig[modalSetting.type]}
          setModalSetting={setModalSetting}
          modalSetting={modalSetting}
        />
      )}
      {/* 頁面 */}
      <ScanHeader
        title={`${Boolean(isEditInfo) ? t("editProjectInformation") : t("createProjectInformation")}`}
        clickEvent={handleCloseEvent}
      />
      <div className="contentContainer">
        <div className="projectInfoContainer">
          <div className="cardContainer">
            <div className="projectThumbnail">
              {modelInfo.status === "DONE" ? (
                <img className="thumbnailImage" src={projectInfo.modelThumb} alt="model-thumb" />
              ) : modelInfo.status === "PENDING" ? (
                // 狀態PENDING為處理失敗
                <div style={{ color: "#F5314D" }}>系統處理失敗</div>
              ) : (
                // 其他狀態為處理中
                <div style={{ color: "#333333" }}>系統處理中...</div>
              )}
            </div>
            <div className="projectDetails">
              <div className="detailList">
                <div className="listKey">
                  <HiMenuAlt3 />
                  <span>{t("projectType")}</span>
                </div>
                <div className="listValue scankitTag">ScanKit</div>
              </div>
              <div className="detailList">
                <div className="listKey">
                  <HiMenuAlt3 />
                  <span>{t("projectStatus")}</span>
                </div>
                <div
                  className={`listValue scankitTag ${projectInfo.publicSwitch ? "publicTag" : "draftTag"}`}
                >
                  {projectInfo.publicSwitch ? t("public") : t("draft")}
                </div>
              </div>
              <div className="detailList">
                <div className="listKey">
                  <FaFolderPlus />
                  <span>{t("createTime")}</span>
                </div>
                <div className="listValue highlightText">{projectInfo.dateCreated}</div>
              </div>
              <div className="detailList">
                <div className="listKey">
                  <FaFolderPlus />
                  <span>{t("latestPublicTime")}</span>
                </div>
                <div
                  className={`listValue ${projectInfo.publicSwitch ? "highlightText" : "disableText"}`}
                >
                  {projectInfo.publicSwitch ? projectInfo.lastPublic : "----/--/--"}
                </div>
              </div>
              <div className="detailList">
                <div className="listKey">
                  <FaFolderPlus />
                  <span>{t("lastEditTime")}</span>
                </div>
                <div className="listValue highlightText">{projectInfo.lastUpdated}</div>
              </div>
              {Boolean(isEditInfo) && !projectInfo.publicSwitch && (
                <div className="publicButton" onClick={handlePublicEvent}>
                  {t("public")}
                </div>
              )}
            </div>
          </div>
          <div className="cardContainer">
            <div className="formInputSection">
              <div className="labelPanel">
                <div className="projectName">{t("projectName")}</div>
                <div className="textCounter">{projectInfo.name.length}/80</div>
              </div>
              <input
                type="text"
                className="textInput"
                maxLength={80}
                value={projectInfo.name}
                onChange={changeProjectName}
              />
            </div>
            <div className="formInputSection">
              <div className="labelPanel">
                <div className="projectName">{t("projectDescription")}</div>
                <div className="textCounter">{projectInfo.description.length}/1000</div>
              </div>
              <textarea
                className="textInput"
                rows={6}
                maxLength={1000}
                value={projectInfo.description}
                onChange={changeDescription}
              />
            </div>
          </div>
        </div>
        <div className="buttonContainer">
          <div className="buttonWrapper">
            <div className="buttonGroup">
              {Boolean(isEditInfo) ? (
                <ScanButton type="secondary" text={t("cancel")} clickEvent={handleCloseEvent} />
              ) : (
                <ScanButton
                  type="secondary"
                  text={t("reupload")}
                  clickEvent={handleReuploadEvent}
                  disabled={modelInfo.status !== "DONE"}
                />
              )}
            </div>
            <div className="buttonGroup">
              <ScanButton
                type="primary-outline"
                icon={saveLoading && <BsArrowRepeat className="arrowIcon" />}
                text={t("save")}
                clickEvent={handleSaveEvent}
              />
              <ScanButton
                type="primary"
                text={t("saveEdit")}
                clickEvent={handleSaveAndEditEvent}
                disabled={modelInfo.status !== "DONE"}
              />
            </div>
          </div>
        </div>
      </div>
    </StyledScanKitInfo>
  );
};

export default ScanKitInfo;
