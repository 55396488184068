import { RESET_ALL, SET_SCANKIT_IMAGE_LIST } from "redux/types";

const defaultImageList = {
  scanFileList: [],
};

const imageListReducer = (state = defaultImageList, action) => {
  switch (action.type) {
    case SET_SCANKIT_IMAGE_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_ALL:
      return defaultImageList;
    default:
      return state;
  }
};

export default imageListReducer;
