// images
import { ReactComponent as Checked } from "./images/checked.svg";

const CheckIcon = (props) => {
  const checkStatus = props.check;
  return (
    <>
      {checkStatus && (
        <div style={{ width: "24px", height: "24px" }}>
          <Checked />
        </div>
      )}
    </>
  );
};

export default CheckIcon;
