import styled from "styled-components";

export const StyledLoadingBar = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .whiteBg {
    width: 560px;
    height: 360px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .Loadingbar {
      width: 400px;
      height: 8px;
      background: #e0e0e0;
      border-radius: 12px;
      margin-bottom: 24px;
      .progress {
        width: ${(props) => (props.progress / 100) * 400 + "px"};
        background: #54c68f;
        height: 8px;
        border-radius: 12px;
      }
    }
    .file {
      background-color: rgba(237, 41, 117, 0.08);
      border-radius: 55px;
      width: 304px;
      height: 72px;
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 64px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .fileIcon {
        vertical-align: middle;
        width: 64px;
        margin-left: 5px;
        margin-top: 4px;
      }
      span {
        vertical-align: middle;
        color: #333;
        font-weight: bold;
        font-size: 16px;
        padding-left: 10px;
        display: inline-block;
        overflow: hidden;
        max-width: 200px;
        text-overflow: ellipsis;
      }
    }
  }
`;
