import { useState, useCallback, useEffect } from "react";
import { throttle } from "lodash-es";
import { getCode, updateCode } from "api/objectCode";
// plugin
import Cookies from "js-cookie";

const ObjectCode = () => {
  const [objectCode, setObjectCode] = useState("No Code");
  const projectId = Cookies.get("projectId");

  const fetchObjectCode = useCallback(async () => {
    try {
      const data = await getCode(projectId);
      if (data) setObjectCode(data.objectCode);
    } catch (error) {
      console.log("There is an error while fetching object code", error);
    }
  }, []);

  useEffect(() => {
    fetchObjectCode();
  }, [fetchObjectCode]);

  const throttleRefresh = throttle(async () => {
    try {
      const data = await updateCode(projectId);
      if (data) setObjectCode(data.objectCode);
    } catch (error) {
      console.log("There is an error while refreshing object code", error);
    }
  }, 1500);

  const refreshObjectCode = useCallback(() => throttleRefresh(), []);

  return (
    <div className="objectCode">
      <div className="codeText">{objectCode}</div>
      <button className="refreshCodeButton" onClick={refreshObjectCode}>
        <span className="codeIcon"></span>
      </button>
    </div>
  );
};

export default ObjectCode;
