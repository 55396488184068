import styled, { css } from "styled-components";

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding: ${({ size }) => (size === "lg" ? "12px 24px" : size === "md" ? "8px 24px" : "4px 16px")};
  border-radius: 8px;
  cursor: pointer;

  > svg {
    font-size: 20px;
  }

  > span {
    font-size: ${({ size }) => (size === "lg" ? "16px" : "14px")};
  }

  /* button 類型有： primary / secondary / dark / light */
  ${({ type }) => {
    switch (type) {
      case "primary":
        return css`
          background: #e01d62;
          border: 1px solid #e01d62;
          color: #ffffff;

          &:hover {
            background: #ff5e8f;
            border: 1px solid #ff5e8f;
          }

          &:disabled {
            background: #f6bbd0;
            border: 1px solid #f6bbd0;
          }
        `;
      case "secondary":
        return css`
          background: #ff7043;
          border: 1px solid #ff7043;
          color: #ffffff;

          &:hover {
            background: #ffa270;
            border: 1px solid #ffa270;
          }

          &:disabled {
            background: #ffd4c7;
            border: 1px solid #ffd4c7;
          }
        `;
      case "dark":
        return css`
          background: #27282c;
          border: 1px solid #27282c;
          color: #ffffff;

          &:hover {
            background: #47484b;
            border: 1px solid #47484b;
          }

          &:disabled {
            background: #c9c9ca;
            border: 1px solid #c9c9ca;
          }
        `;
      case "light":
        return css`
          background: #ebebeb;
          border: 1px solid #ebebeb;
          color: #595959;

          &:hover {
            background: #d9d9d9;
            border: 1px solid #d9d9d9;
          }

          &:disabled {
            background: #ebebeb;
            border: 1px solid #ebebeb;
            color: #ffffff;
          }
        `;
    }
  }}
`;
