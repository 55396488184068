import styled from "styled-components";
import ReactTooltip from "react-tooltip";

export const StyledTooltip = styled(ReactTooltip)`
  width: 240px !important;
  padding: 16px 16px 24px 16px !important;
  text-align: left !important;
  opacity: 1 !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px !important;

  > p {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #262626;
    margin-bottom: 16px;
  }

  .limitation {
    border-left: 1px solid #bfbfbf;
    padding-left: 8px;
    margin-left: 8px;
  }

  .tooltip_list {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-bottom: 8px;

    > svg {
      width: 22px;
      height: 22px;
    }

    > span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #595959;
    }
  }
`;

export const StyledHeader = styled.header`
  width: 100%;
  height: 160px;
  background: linear-gradient(79.78deg, #e01d62 0%, #ff7043 100%);
  /* position: fixed;
    top: 0;
    left: 0;
    z-index: 999999; */

  @media (max-width: 414px) {
    overflow: hidden;
  }

  .popupUpgrade {
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;

    .content-card-modal {
      z-index: 1;
      max-width: 978px;
      width: 100%;
      border-radius: 8px;
      position: relative;
      animation: animate 0.3s;
      overflow: hidden;
      margin: 0 auto;
      @media all and (max-width: 992px) {
        width: 100%;
      }
      .modal-card {
        width: 50%;
        text-align: left;
        background: white;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 32px 24px 48px 24px;
        overflow: hidden;
        @media all and (max-width: 1366px) {
          height: 722px;
        }
        @media all and (max-width: 992px) {
          width: 88.9%;
          height: 348px;
          margin-bottom: 40px;
        }
        @media screen and (max-width: 768px) {
          width: 85.1%;
        }
        @media screen and (max-width: 414px) {
          width: 88.4%;
        }
        &:nth-child(2) {
          @media all and (max-width: 992px) {
            height: 678px;
          }
          @media screen and (max-width: 768px) {
            height: 722px;
          }
          @media screen and (max-width: 414px) {
            height: 744px;
          }
        }
      }
    }
    @media (max-width: 992px) {
      overflow: auto;
      position: absolute;
      padding-top: 100px;
      padding-bottom: 100px;
    }
    @media (max-width: 414px) {
      padding-top: 0px;
      padding-bottom: 0px;
      overflow-x: hidden;
    }
    .upgradeBg {
      width: 1110px;
      background-color: #f2f2f2;
      border-radius: 8px;
      overflow: hidden;
      @media all and (max-width: 1366px) {
        width: 71.6%;
        height: 874px;
      }
      @media (max-width: 992px) {
        width: 72.8%;
        height: 1218px;
      }
      @media (max-width: 768px) {
        width: 69.8%;
        height: 1262px;
      }
      @media (max-width: 414px) {
        width: 100%;
        height: 1424px;
        border-radius: 0px;
      }
    }
  }

  .message {
    min-width: 843px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px 8px 24px;
    background: #54c68f;
    border-radius: 4px;
    color: #fff;
    position: absolute;
    top: 212px;
    .checkCircle {
      font-size: 20px;
      margin-right: 8px;
    }
    .closeIcon {
      font-size: 24px;
      cursor: pointer;
    }
    .resumeButton {
      text-decoration: underline;
      color: rgba(255, 255, 255, 0.6);
      cursor: pointer;
      margin: 0 4px;
      &:hover {
        color: #ffffff;
      }
    }
  }

  .errorBackground {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    .errorContainer {
      width: 560px;
      border-radius: 12px;
      background: #fff;
      padding: 48px;
      position: relative;
    }
    .errorClose {
      font-size: 24px;
      color: #666666;
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  .top {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ll {
      height: 100%;

      .logoName {
        color: #fff;
        font-size: 25px;
        padding: 12px 20px;
        background: rgba(0, 0, 0, 0.12);
        img {
          display: block;
        }
      }
    }

    .rr {
      display: flex;
      column-gap: 12px;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      .feature {
        padding: 4px 16px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 40px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #ffffff;
      }

      .uploadBtn {
        padding: 8px 24px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #262626;

        &:hover {
          background: rgba(255, 255, 255, 1);
        }
      }

      .langBtn {
        padding: 8px 24px;
        border-radius: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #ffffff;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 8px;

        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }

        .globa {
          font-size: 14px;
        }

        .slideDown {
          display: none;
          position: absolute;
          left: 0px;
          top: 44px;
          padding: 6px 0px;
          background: #ffffff;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
          border-radius: 8px;

          .list {
            text-align: left;
            display: flex;
            column-gap: 8px;
            justify-content: space-between;
            align-items: center;
            padding: 6px 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #262626;

            &:hover {
              color: #ff744e;
            }

            span {
              width: 97px;
            }

            svg {
              visibility: hidden;
            }
          }
          .current {
            svg {
              visibility: visible;
            }
          }
        }

        .slideDown.show {
          display: block;
        }
      }

      .line {
        width: 1px;
        height: 20px;
        background: #ffffff;
        opacity: 0.4;
      }

      .hoverCon {
        position: relative;
        box-sizing: content-box;
        margin-right: 24px;

        .arrowUnder {
          color: #fff;
        }
      }

      .box {
        overflow: hidden;
        position: absolute;
        top: 36px;
        right: 0px;
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        width: 240px;
        height: 180px;
        text-align: center;
        z-index: 999;

        svg {
          margin-top: 30px;
          color: #737376;
          margin-bottom: 5px;
          font-size: 35px;
        }

        .username {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;
          color: #262626;
          width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0 auto;
          white-space: nowrap;

          span {
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            letter-spacing: 0.01em;
            color: #8c8c8c;
          }
        }

        .logoutBtn {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          border-top: 1px solid #cccccc;
          padding: 8px 16px;
          margin-top: 24px;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.02em;
          color: #262626;

          svg {
            position: relative;
            width: 16px;
            top: 3px;
            font-size: 14px;
            margin-right: 5px;
            margin-top: 0;
            fill: #333;
            margin-left: 10px;
          }

          &:hover {
            color: #ff744e;
            svg {
              fill: #ff744e;
            }
          }
        }
      }

      .svgIcon {
        color: #fff;
        font-size: 25px;
        cursor: pointer;
        float: left;
      }
      .arrowUnder {
        float: left;
        margin-left: 5px;
        margin-top: 5px;
      }
    }
  }

  .bottom {
    width: 100%;
    height: 104px;
    background: rgba(0, 0, 0, 0.12);
    padding: 25px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left_section {
      display: flex;
      align-items: center;
      column-gap: 40px;

      .usage_info {
        display: flex;
        align-items: center;
        column-gap: 8px;

        &_icon {
          width: 50px;
          height: 50px;
        }

        &_title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;
          color: rgba(255, 255, 255, 0.65);
        }

        &_count {
          text-align: left;
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
          letter-spacing: 0.01em;
          color: rgba(255, 255, 255, 0.85);

          .project_count {
            font-size: 20px;
            line-height: 29px;
            text-decoration-line: underline;
          }
        }
      }

      .divider_outer {
        width: 1px;
        height: 32px;
        background: rgba(255, 255, 255, 0.3);
      }

      .contract_info {
        .contract_period {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;
          color: rgba(255, 255, 255, 0.87);
          margin-bottom: 4px;
        }

        .feature_button {
          display: flex;
          justify-content: start;

          > a {
            padding: 4px 16px;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 32px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: #ffffff;

            &:hover {
              background: rgba(255, 255, 255, 0.3);
            }
          }
        }
      }
    }

    .right_section {
      /* display: none; */
    }
  }
`;
