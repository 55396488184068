import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//styled
import { StyledUpgradeCon } from "./StyledUpgrade";

//components
import Upgrade from "components/Upgrade/Upgrade";

//i18n
import { useTranslation } from "react-i18next";

const UpgradeCon = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <StyledUpgradeCon>
      <Upgrade />
    </StyledUpgradeCon>
  );
};

export default UpgradeCon;
