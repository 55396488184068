// api
import axiosApi, { setHeader } from "api/root.api";

export const modelUpload = async (body, token, projectId, modelId, fileType, handleUploadBar) => {
  let urlEnd = "";
  switch (fileType) {
    case "gltf":
      urlEnd = `/editor/project/v1/${projectId}/${modelId}/uploadGltf`;
      break;
    case "glb":
      urlEnd = `/editor/project/v1/${projectId}/${modelId}/uploadGlb`;
      break;
    case "splat":
      urlEnd = `/editor/project/v1/${projectId}/${modelId}/uploadSplat`;
      break;
    case "ksplat":
      urlEnd = `/editor/project/v1/${projectId}/${modelId}/uploadKsplat`;
      break;
  }
  setHeader("Authorization", `Bearer ${token}`);
  const response = await axiosApi.post(urlEnd, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      handleUploadBar(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    },
  });
  const result = response.data;
  return result;
};
