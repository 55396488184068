import axiosApi, { setHeader } from "api/root.api";

export const updateModel = async (body, token, projectId, modelId) => {
  const urlEnd = `/editor/project/v1/${projectId}/${modelId}/updateModel`;
  setHeader("Authorization", `Bearer ${token}`);
  setHeader("Content-Type", "application/json");
  const response = await axiosApi.post(urlEnd, body);
  const result = response.data;
  return result;
};
