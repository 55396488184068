// api
import axiosApi, { setHeader } from "api/root.api";

export const projectPicture = async (file, projectId, modelId, token) => {
  const urlEnd = `/editor/project/v1/${projectId}/${modelId}/uploadModelThumb`;
  setHeader("Authorization", `Bearer ${token}`);
  setHeader("Content-Type", "multipart/form-data");
  const response = await axiosApi.post(urlEnd, file);
  const result = response.data;
  return result;
};
