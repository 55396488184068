import { useState, useEffect } from "react";

// styled components
import { StyledCookieBanner } from "./StyledCookieBanner";

// plugin
import Cookies from "js-cookie";

// i18n
import { useTranslation, Trans } from "react-i18next";

// constants
import SERVER from "constants/server";

const CookieBanner = () => {
  const { t } = useTranslation();

  const [cookieBanner, setCookieBanner] = useState(false);

  const handleClickEvent = () => {
    // cookie效期設定為一年(365天)
    Cookies.set("istaging_cookie", "all_agreed", { domain: SERVER.GET_DOMAIN, expires: 365 });
    setCookieBanner(false);
  };

  useEffect(() => {
    if (Cookies.get("istaging_cookie") === undefined) {
      // 尚未按過同意，故需要顯示banner
      setCookieBanner(true);
    }
  }, []);

  return (
    <StyledCookieBanner cookieBanner={cookieBanner}>
      <div className="cookie_text">
        <Trans i18nKey="banner.cookieText">
          我們記錄 cookie
          資訊，以提供客製化內容，可優化您的使用體驗，若繼續閱覽本網站內容，即表示您同意我們使用
          cookies。 更多關於隱私保護資訊，請閱覽我們的
          <a href="https://www.istaging.com/en/privacy/" target="_blank">
            隱私權保護政策
          </a>
          。
        </Trans>
      </div>
      <div className="cookie_button" onClick={handleClickEvent}>
        {t("banner.cookieButton")}
      </div>
    </StyledCookieBanner>
  );
};

export default CookieBanner;
