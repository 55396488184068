import { TOGGLE_SWITCH } from "redux/types";

const defaultValue = {
  scanSwitch: true,
  tagSwitch: true,
};

const switchReducer = (state = defaultValue, action) => {
  switch (action.type) {
    case TOGGLE_SWITCH:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default switchReducer;
