import styled from "styled-components";

export const StyledScanViewerTag = styled.div`
  position: absolute;
  left: ${({ left }) => left + "%"};
  top: ${({ top }) => top + "%"};

  .tagBadge {
    display: block;
    width: 24px;
    height: 24px;
    background: rgba(255, 112, 67, 0.9);
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    text-align: center;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 0px 6px rgba(255, 112, 67, 0.5);
    }
  }

  .tagPanel {
    width: 200px;
    height: auto;
    max-height: 350px;
    border-radius: 8px;
    background: rgb(0, 0, 0);
    padding: 10px;
    color: rgba(255, 255, 255);
    position: absolute;
    /* 標籤位置在上方，面板顯示在下方 ; 標籤位置在下方，面板顯示在上方 */
    top: ${({ top }) => (top < 50 ? "0px" : "auto")};
    bottom: ${({ top }) => (top < 50 ? "auto" : "0px")};
    /* 標籤位置在左邊，面板顯示在左邊 ; 標籤位置在右邊，面板顯示在右邊 */
    left: ${({ left }) => (left < 50 ? "auto" : "30px")};
    right: ${({ left }) => (left < 50 ? "30px" : "auto")};
    z-index: 999;

    /* 標籤面板在手機版時，顯示在中間正下方或正上方 */
    @media all and (max-width: 576px) {
      top: ${({ top }) => (top < 50 ? "32px" : "auto")};
      bottom: ${({ top }) => (top < 50 ? "auto" : "32px")};
      left: -88px;
      right: auto;
      justify-content: center;
    }

    /* 標籤面板在手機版時，如果內容過多，則「隱藏內容」並顯示「查看更多」 */
    .tagPanelContent {
      display: flex;
      width: 100%;
      flex-direction: column;
      row-gap: 8px;

      @media all and (max-width: 576px) {
        display: ${({ tooMuchContent }) => (tooMuchContent ? "none" : "flex")};
      }
    }

    /* 標籤面板在手機版時，如果內容過多，則「隱藏內容」並顯示「查看更多」 */
    .seeMoreButton {
      display: none;
      cursor: pointer;

      @media all and (max-width: 576px) {
        display: ${({ tooMuchContent }) => (tooMuchContent ? "flex" : "none")};
        align-items: center;
        justiify-content: center;
      }

      > svg {
        width: 24px;
        height: 24px;
      }
    }

    .textSection {
      width: 100%;
      height: auto;
      min-height: 20px;
      max-height: 88px;
      text-align: left;
      font-size: 14px;
      overflow-y: auto;
      word-break: break-word;

      /* 整個滾動條 */
      &::-webkit-scrollbar {
        width: 7px;
      }
      /* 滾動條軌道 */
      &::-webkit-scrollbar-track-piece {
        background: transparent;
      }
      /* 滾動條滑塊 */
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.3);
      }
    }

    .imageSection {
      width: 100%;
      height: 100px;
      border-radius: 4px;
      background: rgb(27, 27, 27);
      margin: 4px 0px;

      > img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    .linkSection {
      width: 100%;
      height: 20px;
      text-align: left;
      display: flex;
      flex-direction: row;
      column-gap: 2px;
      align-items: center;
      font-size: 12px;

      > svg {
        width: 18px;
        height: 18px;
      }

      > a {
        display: block;
        width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: rgba(255, 255, 255);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .tagMobilePanel {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    background: rgb(0, 0, 0);
    padding: 100px 48px;
    color: rgba(255, 255, 255);
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: start;
    justify-content: start;

    > div {
      width: 100%;
      text-align: left;
      font-size: 16px;
      word-break: break-all;
    }

    .itemTitle {
      max-height: 60px;
      overflow-y: auto;
    }

    .itemContent {
      max-height: 120px;
      overflow-y: auto;
    }

    .itemImage {
      width: 100%;
      height: 230px;
      border-radius: 4px;
      background: rgb(27, 27, 27);
      margin: 24px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .itemLink {
      display: flex;
      flex-direction: row;
      column-gap: 2px;
      align-items: center;

      > svg {
        width: 24px;
        height: 24px;
      }

      > a {
        display: block;
        width: 85%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: rgba(255, 255, 255);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .closeButton {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
  }
`;
