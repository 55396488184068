// react
import React, { useEffect, useState } from "react";

// styled components
import { StyledModalPopupContainer } from "./StyledModalPopup";

// react icons
import { AiOutlineClose, AiFillCheckCircle } from "react-icons/ai";

// redux
import { useSelector } from "react-redux";

//i18n
import { useTranslation } from "react-i18next";

//image
import iconCopy from "./images/copy.svg";

//env
import SERVER from "../../constants/server";

//plugin
import Cookies from "js-cookie";

import clsx from "classnames";

const ModalPopup = ({
  modalConfig,
  setModalSetting,
  modalSetting,
  setCanHoverSetting,
  setPreventMouseEvent,
  scanViewerUrl,
}) => {
  const { icon, type, desc, notice } = modalConfig;
  const { title } = modalSetting;
  const notice2 = modalSetting.notice;
  const { t } = useTranslation();
  const [transferEmail, setTransferEmail] = useState("");
  const [emailError, setEmailError] = useState('');

  const handleCloseModal = () => {
    if (modalSetting.handleOtherAction) {
      modalSetting.handleConfirm();
    } else {
      setModalSetting({
        ...modalSetting,
        show: false,
      });
    }
    if (setCanHoverSetting) {
      setCanHoverSetting(true);
    }
    if (setPreventMouseEvent) {
      setPreventMouseEvent(false);
    }
  };

  const handlModalConfirm = () => {
    if (type === "file-transfer" && emailError ) {
      return;
    }

    if(type === "file-transfer"){
      modalSetting.handleConfirm(transferEmail);
    }else {
      modalSetting.handleConfirm();
    }
    return setModalSetting({
      ...modalSetting,
      show: false,
    });
  };

  const confirmButtons = () => {
    return (
      <div
        className={clsx("confirmButtons", "flexBetweenCenter", {
          flexReverseRow: type === "file-upload" || type === "file-upload2",
        })}
      >
        <div className="confirmButton flexCenter" onClick={handlModalConfirm}>
          {(type === "file-delete" || type === "delete") && t("deleteProject")}
          {(type === "exit-editor" || type === "exit-tag" || type === "file-share") && t("leave")}
          {type === "file-save" && t("saveAndPublic")}
          {type === "file-upload" && t("upgradenow")}
          {type === "file-upload2" && t("common.ContactNow")}
          {type === "file-preview" && t("saveAndPreview")}
          {type === "file-copy" && t("duplicatedDraft")}
          {type === "file-close" && t("unpublic")}
          {type === "file-transfer" && t("transferModalConfirm")}
          {type === "alert-transfer" && t("transferErrorModalConfirm")}
        </div>
        <div className="cancelButton flexCenter" onClick={handleCloseModal}>
          { type === "alert-transfer" ? t("transferErrorModalCancel") : t("cancel")}
        </div>
      </div>
    );
  };

  const alertButton = () => {
    return (
      <div className="alertButton flexCenter">
        <div className="okButton flexCenter" onClick={handleCloseModal}>
          {modalSetting.otherBtnText || t("ok")}
        </div>
      </div>
    );
  };

  const renderDeleteDesc = () => {
    let sliceTitle = title ? title : " ";
    if (sliceTitle.length > 15) {
      sliceTitle = `${sliceTitle.slice(0, 15)}...`;
    }
    desc[1] = `"${sliceTitle}"`;
    const joinDesc = desc.join(" ");
    return joinDesc;
  };

  //只給分享用

  const [shareUrl, setShareUrl] = useState("");
  const store = useSelector((store) => store);
  const [copyStatus, setCopyStatus] = useState(false);

  useEffect(() => {
    let uuid = store.cardListInfo.cardListDetail.filter(
      (item) => item.id === parseFloat(Cookies.get("projectId")),
    )[0]?.uuid;
    if (scanViewerUrl) {
      setShareUrl(`${SERVER.ARPLATFORM}/viewer?uid=${uuid}`);
    } else {
      setShareUrl(`${SERVER.VIEWER_URL}/?uid=${uuid}&openExternalBrowser=1`);
    }
  }, []);

  const copyEvent = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopyStatus(true);
      setTimeout(() => setCopyStatus(false), 2000);
    });
  };

  const stopEvent = (e) => {
    e.stopPropagation();
  };

  const handleEmailChange = (e) => {
    setTransferEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (email === '') {
      return 'Please enter email';
    } else if (!emailRegex.test(email)) {
      return 'Email format is incorrect';
    }
    return '';
  };

  useEffect(() => {
    setEmailError(validateEmail(transferEmail));
  },[transferEmail])

  return (
    <StyledModalPopupContainer
      onClick={stopEvent}
      className="flexCenter"
      type={type}
      coverSetting={modalSetting.coverSetting}
      iframe={modalSetting.iframe}
      mode={modalSetting.mode}
    >
      {copyStatus && (
        <div className="copySuccess">
          <AiFillCheckCircle />
          {t("successfullyCopied")}
        </div>
      )}
      <div className="modalPanel" onClick={stopEvent}>
        {!modalSetting.noClose && (
          <div
            className="topSection flexEndCenter"
            onClick={handleCloseModal}
            style={{ display: modalSetting.coverSetting ? "none" : "visiable" }}
          >
            <AiOutlineClose />
          </div>
        )}

        <div className="bottomSection flexCenter flexWrap flex-column">
          <div className="iconPanel">
            <img src={icon} alt="delete icon" />
          </div>
          <div style={{width:'100%'}}>
            <div className="descPanel">
              {type === "delete" && renderDeleteDesc()}
              {type !== "delete" && !modalSetting.desc && desc}
              {modalSetting.desc && <p dangerouslySetInnerHTML={{ __html: modalSetting.desc }}></p>}
            </div>
            <div
              className="noticePanel"
              dangerouslySetInnerHTML={{ __html: notice2 ? notice2 : notice }}
            ></div>
          </div>
          {type === "file-transfer" && (
              <div className="transfer">
                <div className="transfer-label">{t('transferModalInput')} <span style={{color:"red"}}>*</span></div>
                <div>
                  <div className="transfer-input-wrapper">
                    <input type="text" value={transferEmail} onChange={handleEmailChange}/>
                  </div>
                  {emailError && <div className="transfer-input-error-message">{emailError}</div>}
                </div>
              </div>
          )}
          <div className="buttonPanel" style={{ display: type === "file-share" && "none" }}>
            {(type === "file-delete" ||
              type === "delete" ||
              type === "exit-editor" ||
              type === "exit-tag" ||
              type === "file-save" ||
              type === "file-preview" ||
              type === "file-copy" ||
              type === "file-transfer" ||
              type === "alert-transfer" ||
              type === "file-upload" ||
              type === "file-upload2" ||
              type === "file-close") &&
              confirmButtons()}
            {(type === "alert-format" ||
              type === "alert-storage" ||
              type === "alert-tag-name" ||
              type === "file-share" ||
              type === "loading-err") &&
              alertButton()}
          </div>
          {type === "file-share" && (
            <div className="shareOnly">
              <input type="text" value={shareUrl} readOnly />
              <div className="iconCon" onClick={copyEvent}>
                <img src={iconCopy} />
              </div>
            </div>
          )}
        </div>
      </div>
    </StyledModalPopupContainer>
  );
};

export default ModalPopup;
