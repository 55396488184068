import React from "react";
import { StyledAbnormalPopup } from "./StyledAbnormalPopup";
import { ReactComponent as ErrorIcon } from "./images/error-icon.svg";
import { getOfficialWebsiteLang } from "utils/language";
import SERVER from "constants/server";
import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";

const AbnormalPopup = () => {
  const { t } = useTranslation();

  const handleContactEvent = () => {
    const owLang = getOfficialWebsiteLang();
    window.open(`${SERVER.AR_MAKER}/${owLang}/company/contact_us?skipIntroVideo=true`);
  };

  return (
    <StyledAbnormalPopup>
      <div className="abnormal_popup">
        <ErrorIcon />
        <p className="abnormal_popup_title">{t("abnormalPopup.accountError")}</p>
        <p className="abnormal_popup_subtitle">{t("abnormalPopup.assistYouSoon")}</p>
        <div className="abnormal_popup_buttons">
          <Button type="dark" text={t("callHelp")} clickEvent={handleContactEvent} />
        </div>
      </div>
    </StyledAbnormalPopup>
  );
};

export default AbnormalPopup;
