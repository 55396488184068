import { useState } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_OLD_TAG_TITLE,
  SET_OLD_TAG_CONTENT,
  SET_OLD_TAG_FILE,
  SET_OLD_TAG_URL,
  REMOVE_OLD_TAG,
  REMOVE_OLD_TAG_ICON,
  SET_NEW_TAG_TITLE,
  SET_NEW_TAG_CONTENT,
  SET_NEW_TAG_FILE,
  SET_NEW_TAG_URL,
  REMOVE_NEW_TAG,
  SET_EDITING_TAG,
} from "redux/types";

// styles
import { StyledScanEditorTag } from "./StyledScanEditorTag";

// images
import { ReactComponent as DeleteIcon } from "./images/Delete.svg";
import { ReactComponent as UploadIcon } from "./images/Upload.svg";
import { ReactComponent as ReuploadIcon } from "./images/Reupload.svg";
import { ReactComponent as AlertIcon } from "./images/Alert.svg";

// plugin
import Dropzone from "react-dropzone";
import Cookies from "js-cookie";

// i18n
import { useTranslation } from "react-i18next";

// api
import { deleteTag } from "api/scankit/tag.api";

const ScanEditorTag = ({ item, index, images, newTag, displayPanel, setDisplayPanel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tagSwitch } = useSelector((store) => store.switch);
  const editingTags = useSelector((store) => store.tagList.editingTags);

  const handleClickTagBadge = (id) => {
    // 標籤為id的面板已展開則收合
    if (id === displayPanel) {
      setDisplayPanel(null);
    }
    // 標籤為id的面板未展開則展開
    if (id !== displayPanel) {
      setDisplayPanel(id);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleDeleteEvent = () => {
    dispatch({
      type: OPEN_MODAL,
      payload: {
        isOpen: true,
        icon: "delete",
        title: `要刪除 "${item.title}" 標籤嗎?`,
        description: "標籤刪除後將無法復原。",
        button1: "取消",
        button2: "刪除",
        handleConfirm: () => {
          try {
            if (item.id) {
              // delete tag already exits, api calls will be implemented
              dispatch({ type: REMOVE_OLD_TAG, payload: item.id });
              deleteTag(Cookies.get("scanProjectId"), Cookies.get("scanModelId"), item.id);
            }

            if (!item.id) {
              // delete tag who is not yet created via api, only remove from dom
              dispatch({ type: REMOVE_NEW_TAG, payload: item.uniqueId });
            }

            dispatch({ type: CLOSE_MODAL });
          } catch (error) {
            console.log(error);

            dispatch({
              type: OPEN_MODAL,
              payload: {
                isOpen: true,
                icon: "error",
                title: "刪除時發生錯誤",
                description: t("interneterror"),
                button2: t("PleaseTryAgain"),
                handleConfirm: () => {
                  window.location.reload();
                },
              },
            });
          }
        },
      },
    });
  };

  const handleInputChange = (e) => {
    // 舊標籤被編輯時，提示「尚未儲存變更」乙次
    if (item.id) {
      if (editingTags.indexOf(item.id) === -1) {
        dispatch({ type: SET_EDITING_TAG, payload: item.id });
      }
    }

    let reduxType;

    if (e.target.name === "title") reduxType = item.id ? SET_OLD_TAG_TITLE : SET_NEW_TAG_TITLE;
    if (e.target.name === "content")
      reduxType = item.id ? SET_OLD_TAG_CONTENT : SET_NEW_TAG_CONTENT;
    if (e.target.name === "url") reduxType = item.id ? SET_OLD_TAG_URL : SET_NEW_TAG_URL;

    const tagId = item.id ? item.id : item.uniqueId;

    dispatch({ type: reduxType, payload: { id: tagId, value: e.target.value } });
  };

  const [fileSrc, setFileSrc] = useState(null);

  const handleFileInputChange = (acceptedFiles, rejectedFiles) => {
    // console.log(acceptedFiles);
    // console.log(rejectedFiles);

    if (rejectedFiles.length > 0) {
      // 檔案不符合格式或超過檔案大小
      dispatch({
        type: OPEN_MODAL,
        payload: {
          isOpen: true,
          icon: "oops",
          title: t("modal.incorrectFileFormat"),
          // description: t('modal.fileFormatLimitation'),
          button2: t("common.confirm"),
          handleConfirm: () => {
            dispatch({ type: CLOSE_MODAL });
          },
        },
      });
    } else {
      const reader = new FileReader();
      const file = acceptedFiles[0];
      reader?.readAsDataURL(file);
      reader.onload = () => {
        // 供檔案即時預覽
        setFileSrc(reader.result);

        // 暫存檔案資訊
        if (item.id) {
          dispatch({ type: SET_OLD_TAG_FILE, payload: { id: item.id, value: file } });
          // 舊標籤被編輯時，提示「尚未儲存變更」乙次
          if (editingTags.indexOf(item.id) === -1) {
            dispatch({ type: SET_EDITING_TAG, payload: item.id });
          }
        }
        if (!item.id) {
          dispatch({ type: SET_NEW_TAG_FILE, payload: { id: item.uniqueId, value: file } });
        }
      };
    }
  };

  const handleDeleteFileEvent = () => {
    dispatch({
      type: OPEN_MODAL,
      payload: {
        isOpen: true,
        icon: "delete",
        title: `要刪除 "${item.title}" 縮圖嗎?`,
        description: "縮圖刪除後將無法復原。",
        button1: "取消",
        button2: "刪除",
        handleConfirm: () => {
          if (item.id) {
            dispatch({ type: SET_OLD_TAG_FILE, payload: { id: item.id, value: null } });
            // 舊標籤要讓 redux icon 變空值
            dispatch({ type: REMOVE_OLD_TAG_ICON, payload: item.id });
          }
          if (!item.id) {
            dispatch({ type: SET_NEW_TAG_FILE, payload: { id: item.uniqueId, value: null } });
          }

          // 清空前端顯示
          setFileSrc(null);
          dispatch({ type: CLOSE_MODAL });
        },
      },
    });
  };

  return (
    <StyledScanEditorTag
      newTag={newTag}
      left={item.dataPosition.split(" ")[0]}
      top={item.dataPosition.split(" ")[1]}
      style={{
        display: tagSwitch && images.startIndex === item.scankitIndex ? "block" : "none",
      }}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseMove={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
      onMouseLeave={(e) => e.stopPropagation()}
      onDragStart={(e) => e.stopPropagation()}
      onTouchStart={(e) => e.stopPropagation()}
      onTouchMove={(e) => e.stopPropagation()}
      onTouchEnd={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <span className="tagBadge" onClick={() => handleClickTagBadge(index + 1)}>
        {index + 1}
      </span>
      <div className="tagPanel" style={{ display: displayPanel === index + 1 ? "block" : "none" }}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div className="actionButtons flexBetweenCenter">
            <div>
              {editingTags.indexOf(item.id) !== -1 && (
                <div className="alertText">
                  <AlertIcon />
                  <span>尚未儲存變更</span>
                </div>
              )}
            </div>
            <DeleteIcon onClick={handleDeleteEvent} />
          </div>
          <div className="flexY flexCenterEnd">
            <input
              type="text"
              id="title"
              name="title"
              className="textInput"
              placeholder="標題"
              maxLength={30}
              value={item.title}
              onChange={handleInputChange}
            />
            <span className="textLimitation">{item.title.length}/30</span>
          </div>
          <div className="flexY flexCenterEnd">
            <textarea
              id="content"
              name="content"
              className="textInput"
              style={{ height: "76px", resize: "none", fontFamily: "inherit" }}
              placeholder="內文"
              maxLength={300}
              value={item.content}
              onChange={handleInputChange}
            ></textarea>
            <span className="textLimitation">{item.content.length}/300</span>
          </div>
          <Dropzone
            onDrop={handleFileInputChange}
            accept="image/jpeg, image/png"
            minSize={0}
            maxSize={2097152} // 2MB=1048576*2
          >
            {({ getRootProps, getInputProps }) => (
              <div className="fileField" {...getRootProps()}>
                {fileSrc || item.icon ? (
                  <label htmlFor="icon">
                    <input {...getInputProps()} />
                    <img src={fileSrc ? fileSrc : item.icon} alt="file-image" />
                  </label>
                ) : (
                  <label htmlFor="icon">
                    <input {...getInputProps()} />
                    <div>
                      請上傳圖片小於 2MB 的 JPG、PNG 檔。
                      <span className="uploadButton">
                        <UploadIcon />
                        上傳
                      </span>
                    </div>
                  </label>
                )}
              </div>
            )}
          </Dropzone>
          {(fileSrc || item.icon) && (
            <div className="actionButtons flexStartCenter">
              <DeleteIcon onClick={handleDeleteFileEvent} />
              <Dropzone
                onDrop={handleFileInputChange}
                accept="image/jpeg, image/png"
                minSize={0}
                maxSize={2097152} // 2MB=1048576*2
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <label htmlFor="icon" className="flexCenter">
                      <input {...getInputProps()} />
                      <ReuploadIcon style={{ cursor: "pointer" }} />
                    </label>
                  </div>
                )}
              </Dropzone>
            </div>
          )}
          <div className="flexY flexCenterEnd">
            <input
              type="text"
              id="url"
              name="url"
              className="textInput"
              placeholder="連結"
              value={item.url}
              onChange={handleInputChange}
            />
          </div>
        </form>
      </div>
    </StyledScanEditorTag>
  );
};

export default ScanEditorTag;
