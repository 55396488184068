import { RESET_ALL, SET_SCANKIT_CARD_LIST } from "redux/types";

const defaultCardList = {
  cardListDetail: [],
  totalArchive: 0,
};

const scankitListReducer = (state = defaultCardList, action) => {
  switch (action.type) {
    case SET_SCANKIT_CARD_LIST:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_ALL:
      return defaultCardList;
    default:
      return state;
  }
};

export default scankitListReducer;
