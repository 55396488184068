import * as THREE from "three";

class Three {
  private renderer: THREE.WebGLRenderer;

  constructor() {
    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
    });
    this.renderer.setClearColor(0xffffff);
  }

  init(containerRef: HTMLDivElement): void {
    this.setSize(containerRef);
    containerRef.appendChild(this.renderer.domElement);
  }

  private setSize(containerRef: HTMLElement): void {
    const { clientWidth: width, clientHeight: height } = containerRef;
    this.renderer.setSize(width, height);
  }
}

export default Three;
