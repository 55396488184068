import styled from "styled-components";

export const StyledCardSettingList = styled.div`
  > div {
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;

    > svg {
      fill: #333333;
      width: 24px;
      height: 24px;
      padding: 1px;
    }

    > span {
      color: #333333;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    &:hover {
      > svg {
        fill: #ff744e;
      }
      > span {
        color: #ff744e;
      }
    }

    &:first-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    &:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.12);

      &:hover {
        > svg {
          fill: #f5314d;
        }
        > span {
          color: #f5314d;
        }
      }
    }
  }

  .cannotPublic {
    > svg {
      fill: #bfbfbf;
    }
    > span {
      color: #bfbfbf;
    }
    &:hover {
      > svg {
        fill: #bfbfbf;
      }
      > span {
        color: #bfbfbf;
      }
    }
  }

  .basicTag {
    padding: 2px 8px;
    background: #ffe8e1;
    border: 1px solid #ffe8e1;
    border-radius: 40px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #ff7043 !important;
    margin-left: 12px;
  }
`;
