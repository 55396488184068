import {
  SET_SCANKIT_TAG_LIST,
  SET_EDITING_TAG,
  SET_OLD_TAG_TITLE,
  SET_OLD_TAG_CONTENT,
  SET_OLD_TAG_FILE,
  SET_OLD_TAG_URL,
  REMOVE_OLD_TAG,
  REMOVE_OLD_TAG_ICON,
  SET_NEW_TAG,
  SET_NEW_TAG_TITLE,
  SET_NEW_TAG_CONTENT,
  SET_NEW_TAG_FILE,
  SET_NEW_TAG_URL,
  REMOVE_NEW_TAG,
  RESET_ALL,
} from "redux/types";

const defaultTagList = {
  oldTags: [],
  newTags: [],
  editingTags: [],
};

const tagListReducer = (state = defaultTagList, action) => {
  // update tag input
  const updateInput = ({ targetTags, idName, idValue, inputName, inputValue }) => {
    const updatedInput = targetTags.map((tag) => {
      if (tag[idName] === idValue) {
        tag[inputName] = inputValue;
      }
      return tag;
    });
    return updatedInput;
  };

  switch (action.type) {
    case SET_SCANKIT_TAG_LIST:
      return {
        ...state,
        ...action.payload,
      };
    // old tags
    case SET_EDITING_TAG:
      return {
        ...state,
        editingTags: [...state.editingTags, action.payload],
      };
    case SET_OLD_TAG_TITLE:
      const updateOldTagTitle = updateInput({
        targetTags: state.oldTags,
        idName: "id",
        idValue: action.payload.id,
        inputName: "title",
        inputValue: action.payload.value,
      });
      return {
        ...state,
        oldTags: [...updateOldTagTitle],
      };
    case SET_OLD_TAG_CONTENT:
      const updateOldTagContent = updateInput({
        targetTags: state.oldTags,
        idName: "id",
        idValue: action.payload.id,
        inputName: "content",
        inputValue: action.payload.value,
      });
      return {
        ...state,
        oldTags: [...updateOldTagContent],
      };
    case SET_OLD_TAG_FILE:
      const updateOldTagFile = updateInput({
        targetTags: state.oldTags,
        idName: "id",
        idValue: action.payload.id,
        inputName: "file",
        inputValue: action.payload.value,
      });
      return {
        ...state,
        oldTags: [...updateOldTagFile],
      };
    case SET_OLD_TAG_URL:
      const updateOldTagUrl = updateInput({
        targetTags: state.oldTags,
        idName: "id",
        idValue: action.payload.id,
        inputName: "url",
        inputValue: action.payload.value,
      });
      return {
        ...state,
        oldTags: [...updateOldTagUrl],
      };
    case REMOVE_OLD_TAG:
      const oldTagId = action.payload;
      const remainOldTags = state.oldTags.filter((tag) => tag.id !== oldTagId);
      return {
        ...state,
        oldTags: [...remainOldTags],
      };
    case REMOVE_OLD_TAG_ICON:
      const updateOldTagIcon = updateInput({
        targetTags: state.oldTags,
        idName: "id",
        idValue: action.payload,
        inputName: "icon",
        inputValue: "",
      });
      return {
        ...state,
        oldTags: [...updateOldTagIcon],
      };
    // new tags
    case SET_NEW_TAG:
      return {
        ...state,
        newTags: [...state.newTags, action.payload],
      };
    case SET_NEW_TAG_TITLE:
      const updateNewTagTitle = updateInput({
        targetTags: state.newTags,
        idName: "uniqueId",
        idValue: action.payload.id,
        inputName: "title",
        inputValue: action.payload.value,
      });
      return {
        ...state,
        newTags: [...updateNewTagTitle],
      };
    case SET_NEW_TAG_CONTENT:
      const updateNewTagContent = updateInput({
        targetTags: state.newTags,
        idName: "uniqueId",
        idValue: action.payload.id,
        inputName: "content",
        inputValue: action.payload.value,
      });
      return {
        ...state,
        newTags: [...updateNewTagContent],
      };
    case SET_NEW_TAG_FILE:
      const updateNewTagFile = updateInput({
        targetTags: state.newTags,
        idName: "uniqueId",
        idValue: action.payload.id,
        inputName: "file",
        inputValue: action.payload.value,
      });
      return {
        ...state,
        newTags: [...updateNewTagFile],
      };
    case SET_NEW_TAG_URL:
      const updateNewTagUrl = updateInput({
        targetTags: state.newTags,
        idName: "uniqueId",
        idValue: action.payload.id,
        inputName: "url",
        inputValue: action.payload.value,
      });
      return {
        ...state,
        newTags: [...updateNewTagUrl],
      };
    case REMOVE_NEW_TAG:
      const newTagId = action.payload;
      const remainNewTags = state.newTags.filter((tag) => tag.uniqueId !== newTagId);
      return {
        ...state,
        newTags: [...remainNewTags],
      };
    case RESET_ALL:
      return defaultTagList;
    default:
      return state;
  }
};

export default tagListReducer;
