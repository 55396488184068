import React from "react";
import { useNavigate } from "react-router-dom";

// api
import { deleteProject } from "api/deleteProject/deleteProject.api";
import { deleteModel } from "api/deleteModel/deleteModel.api";
import { getProject } from "api/getProject/getProject.api";

// styled
import { StyledTopBar } from "./StyledTopBar";

// react icon
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import whitecloseIcon from "./images/whitecloseIcon.svg";
import blackcloseIcon from "./images/blackcloseIcon.svg";

// plugin
import Cookies from "js-cookie";

// i18n
import { useTranslation } from "react-i18next";

// constants
import SERVER from "constants/server";

// images
import { ReactComponent as ArObject } from "./images/ArObject.svg";

const TopBar = (props) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const getFileName = urlParams.get("fileName");

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleBackToCardList = () => {
    navigate("/cardList");
  };

  const backEvent = () => {
    if (
      window.location.href.indexOf("/projectInfo") > -1 &&
      window.location.href.indexOf("mode=create") > -1
    ) {
      navigate("/cardList");
    }

    // 第一次創建專案，預設進入素材庫，關閉時返回 CardList
    if (
      window.location.href.indexOf("/upload") > -1 &&
      window.location.href.indexOf("create=true") > -1 &&
      window.location.href.indexOf("useMaterialLab=true") > -1
    ) {
      deleteProject(Cookies.get("projectId"), Cookies.get("token")).then((res) => {
        navigate("/cardList");
      });
      return;
    }

    if (
      window.location.href.indexOf("/upload") > -1 &&
      window.location.href.indexOf("?reload") > -1
    ) {
      navigate("/projectInfo");
    } else if (
      window.location.href.indexOf("/upload") > -1 &&
      window.location.href.indexOf("?fileName") === -1 &&
      window.location.search !== ""
    ) {
      if (props.alreadySave) {
        navigate("/cardList");
      } else {
        deleteModel(Cookies.get("token"), Cookies.get("projectId"), Cookies.get("modelId")).then(
          () => {
            getProject(Cookies.get("token"), Cookies.get("projectId")).then((res) => {
              const token = Cookies.get("token");
              // encodeURIComponent
              const lang = encodeURIComponent(Cookies.get("lang"));
              const encodeProjectId = encodeURIComponent(Cookies.get("projectId"));
              const encodeModelsId = encodeURIComponent(res.project.editors[0].models[0].id);
              const encodeToken = encodeURIComponent(token);
              const encodeisPublish = encodeURIComponent(res.project.editors[0].publicSwitch);
              window.open(
                `${SERVER.EDITOR_URL}/?projectId=${encodeProjectId}&modelsId=${encodeModelsId}&token=${encodeToken}&isPublish=${encodeisPublish}&lang=${lang}`,
                "_self",
              );
            });
          },
        );
      }
    } else if (window.location.href.indexOf("/upload") > -1 && window.location.search === "") {
      if (props.alreadySave) {
        navigate("/cardList");
      } else {
        deleteProject(Cookies.get("projectId"), Cookies.get("token")).then((res) => {
          window.history.back();
        });
      }
    } else if (
      window.location.href.indexOf("/upload") > -1 &&
      window.location.href.indexOf("fileName") > -1 &&
      window.location.href.indexOf("useMaterialLab=true") === -1
    ) {
      navigate({
        pathname: "/projectInfo",
        search: `?mode=create&fileName=${getFileName}`,
      });
    } else if (
      window.location.href.indexOf("/upload") > -1 &&
      window.location.href.indexOf("fileName") > -1 &&
      window.location.href.indexOf("useMaterialLab=true") > -1
    ) {
      navigate({
        pathname: "/projectInfo",
        search: `?mode=create&fileName=${getFileName}&useMaterialLab=true`,
      });
    }
  };

  return (
    <StyledTopBar className="flexStartCenter" noColor={props.noColor} isReload={props.isReload}>
      {props.isEditMode && (
        <div className="leftBackToPlatform flexStartCenter" onClick={handleBackToCardList}>
          <MdOutlineArrowBackIosNew />
          <span>{t("backPlatform")}</span>
        </div>
      )}
      {!props.isEditMode && <div className="holder"></div>}
      <div className="projectTitle">
        {!props.noColor && <ArObject />}
        {props.isReload && (
          <div className="title">
            {t("replace")}"<div className="name">{props.reloadTitle}</div>"{t("object")}
          </div>
        )}
        {!props.isReload && !props.isOther && !props.noColor && props.title}
        {props.isOther && !props.noColor && t("uploadSubobject")}
      </div>
      {props.isEditMode && <div className="holder"></div>}
      {!props.isEditMode && (
        <div className="rightClose flexEndCenter">
          <img src={blackcloseIcon} onClick={backEvent} className="closeIcon" />
        </div>
      )}
    </StyledTopBar>
  );
};

export default TopBar;
