import styled from "styled-components";

export const StyledApp = styled.div`
  text-align: center;
  .hidden {
    display: none;
  }

  .flexX {
    flex-direction: row;
  }

  .flexY {
    flex-direction: column;
  }
  .flexWrap {
    flex-wrap: wrap;
  }
  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flexStartCenter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .flexEndCenter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .flexBetweenCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flexCenterStart {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .flexCenterEnd {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .flexCenterBetween {
    display: flex;
    justify-content: center;
    align-items: space-between;
  }

  .flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
