import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// styled
import { StyledUpload } from "./StyledUpload";

// components
import TopBar from "components/TopBar/TopBar";
import LoadingBar from "components/LoadingBar/LoadingBar";
import ModalPopup from "components/ModalPopup/ModalPopup";

// api
import { getProject } from "api/getProject/getProject.api";
import { getMaterialLib } from "api/getMaterialLib/getMaterialLib.api";
import { modelUpload } from "api/modelUpload/modelUpload.api";
import { deleteProject } from "api/deleteProject/deleteProject.api";
import { deleteModel } from "api/deleteModel/deleteModel.api";
import { reviseProject } from "api/reviseProject/reviseProject.api";
import { useMaterialLib } from "api/useMaterialLib/useMaterialLib.api";
import { projectPicture } from "api/projectPicture/projectPicture.api";
import { updateModel } from "api/updateModel/updateModel.api";

// hooks
import useModal from "hooks/useModal";

// config
import { modalConfig } from "config/modal";

// plugin
import Dropzone from "react-dropzone";
import "@google/model-viewer/dist/model-viewer";
import Cookies from "js-cookie";

// images
import fileUploadIcon from "./images/fileUploadIcon.svg";
import closeIcon from "./images/closeIcon.png";

// i18n
import { useTranslation } from "react-i18next";

// react bootstrap
import ProgressBar from "react-bootstrap/ProgressBar";

// constants
import SERVER from "constants/server";
import ThreeJs from "../../components/ThreeJs/ThreeJs";

const ACCEPTED_FILE_TYPES = ["glb", "gltf", "ksplat", "splat"]; //ksplat needs to come before splat

const Upload = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const getFileName = urlParams.get("fileName");
  const getuseMaterialLab = urlParams.get("useMaterialLab");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const modelViewer = useRef();
  const tootipbox = useRef();
  const { modalSetting, setModalSetting } = useModal();

  const [isUpload, setIsUpload] = useState(false);
  const [startUpload, setStartUpload] = useState(false);
  const [loaderThreeJs, setLoaderThreeJs] = useState(false);
  const [fileModel, setFlieModel] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [otherModelTitle, setOtherTitle] = useState(false);
  const [UploadOption, setUploadOption] = useState(true);
  const [UploadProgress, setUploadProgress] = useState(0);
  const [materialLib, setMaterialLib] = useState([]);
  const [openIframe, setOpenIframe] = useState(false);
  const [materialIframe, setMaterialIframe] = useState(null);
  // model loading progress bar
  const [modelLoadingProgress, setModelLoadingProgress] = useState(0);
  const [showModelErr, setShowModelErr] = useState(false);
  const [openMaterialPicHolder, setOpenMaterialPicHolder] = useState(false);
  const [openNameTootip, setOpenNameTootip] = useState(false);
  const [nameTootip, setNameTootip] = useState("");
  const [threeThumbnail, setThreeThumbnail] = useState("");

  const handleErrorResponse = (modalType) => {
    setModalSetting({
      ...modalSetting,
      show: true,
      title: "",
      coverSetting: true,
      otherBtnText: t("PleaseTryAgain"),
      noClose: true,
      type: modalType,
      handleConfirm: () => {},
    });
  };

  useEffect(() => {
    if (getuseMaterialLab) {
      setUploadOption(false);
    }
    if (
      urlParams.get("projectId") &&
      urlParams.get("modelId") &&
      urlParams.get("token") &&
      urlParams.get("isPublish")
    ) {
      Cookies.set("projectId", decodeURIComponent(urlParams.get("projectId")));
      Cookies.set("modelId", decodeURIComponent(urlParams.get("modelId")));
      Cookies.set("token", decodeURIComponent(urlParams.get("token")));
      Cookies.set("isPublish", decodeURIComponent(urlParams.get("isPublish")));
      setOtherTitle(true);
    } else {
      getProject(Cookies.get("token"), Cookies.get("projectId")).then((res) => {
        Cookies.set("modelId", res.project.editors[0].models[0].id);
      });
    }
    // 處理初始素材庫載入
    setOpenMaterialPicHolder(true);
    let getMoreList = true;
    let page = 0;
    let size = 12;
    getMaterialLib(size, page)
      .then((res) => {
        page++;
        if (res.content.length < size) {
          getMoreList = false;
        }
        setMaterialLib(res.content);
        setOpenMaterialPicHolder(false);
      })
      .catch((err) => {
        setModalSetting({
          ...modalSetting,
          show: true,
          title: "",
          coverSetting: true,
          otherBtnText: t("PleaseTryAgain"),
          noClose: true,
          type: "type22",
          handleConfirm: () => {},
        });
      });
    const handleScroll = () => {
      let cHeight = document.documentElement.clientHeight;
      let sHeight = document.documentElement.scrollHeight;
      let sTop = document.documentElement.scrollTop;
      if (sHeight == Math.round(cHeight + sTop)) {
        if (getMoreList) {
          setOpenMaterialPicHolder(true);
          getMaterialLib(size, page)
            .then((res) => {
              page++;
              if (res.content.length < size) {
                getMoreList = false;
              }
              setMaterialLib((prevState) => {
                return [...prevState, ...res.content];
              });
              setOpenMaterialPicHolder(false);
            })
            .catch((err) => {
              setModalSetting({
                ...modalSetting,
                show: true,
                title: "",
                coverSetting: true,
                otherBtnText: t("PleaseTryAgain"),
                noClose: true,
                type: "type22",
                handleConfirm: () => {},
              });
            });
        }
      }
      let uploadOption = document.querySelector(".uploadOption");
      if (sTop > 20) {
        uploadOption.classList.add("leaveTop");
      } else if (sTop < 20) {
        uploadOption.classList.remove("leaveTop");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // modelviewer
    const getModelViewer = document.querySelector("#myModelViewer");
    getModelViewer.addEventListener("error", (e) => {
      setModalSetting({
        ...modalSetting,
        coverSetting: true,
        otherBtnText: t("PleaseTryAgain"),
        noClose: true,
        handleOtherAction: true,
        iframe: true,
        type: "type37",
        handleConfirm: () => {
          window.location.reload();
        },
      });
      setShowModelErr(true);
    });
    const netStatus = navigator.onLine;
    getModelViewer.addEventListener("progress", (e) => {
      const getProgress = e.detail.totalProgress;
      setModelLoadingProgress(+getProgress.toFixed(2));
      if (!netStatus) {
        setModalSetting({
          ...modalSetting,
          coverSetting: true,
          otherBtnText: t("PleaseTryAgain"),
          noClose: true,
          handleOtherAction: true,
          iframe: true,
          type: "type37",
          handleConfirm: () => {
            window.location.reload();
          },
        });
        setShowModelErr(true);
      }
    });
  }, []);

  const acceptedFiles = (e) => {
    if (e[0].size / (1024 * 1024) > 100) {
      setModalSetting({
        ...modalSetting,
        show: true,
        type: "type14",
        otherBtnText: t("common.confirm"),
      });
      return;
    } else if (e.length > 1) {
      setModalSetting({
        ...modalSetting,
        show: true,
        type: "type15",
        otherBtnText: t("common.confirm"),
      });
      return;
    } else if (!ACCEPTED_FILE_TYPES.some((ext) => e[0].name.includes(ext))) {
      setModalSetting({
        ...modalSetting,
        show: true,
        type: "type16",
        otherBtnText: t("common.confirm"),
      });
      return;
    } else if (e[0].name.length > 80) {
      setModalSetting({
        ...modalSetting,
        show: true,
        type: "type42",
        otherBtnText: t("common.confirm"),
      });
      return;
    }
    const matchedType = ACCEPTED_FILE_TYPES.find((type) => e[0].name.includes(type));
    if (matchedType) {
      setFileType(matchedType);
    }

    setIsUpload(true);
    setFlieModel(e[0]);
    setFileName(e[0].name);
  };

  const [modelFile, setModleFile] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");

  function dataURLtoBlob(dataURL) {
    const [header, base64Data] = dataURL.split(",");
    const mime = header.match(/:(.*?);/)[1];
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  }

  const startUploadEvent = (e) => {
    if (e) e.preventDefault();
    setStartUpload(true);
    if ((fileType === "splat" || fileType === "ksplat") && !loaderThreeJs) {
      setLoaderThreeJs(true);
      return;
    }

    let fileData = new FormData();
    fileData.append("file", fileModel);
    // 從編輯器(Editor)過來，上傳次物件
    if (otherModelTitle) {
      const handleUploadBar = (progress) => {
        if (progress > 96) {
          setUploadProgress(96);
        } else {
          setUploadProgress(progress);
        }
      };
      modelUpload(
        fileData,
        Cookies.get("token"),
        Cookies.get("projectId"),
        Cookies.get("modelId"),
        fileType,
        handleUploadBar,
      )
        .then((res) => {
          setModleFile(res.fileDownloadUri);
          modelViewer.current.addEventListener("progress", async (e) => {
            const getProgress = e.detail.totalProgress;
            if (getProgress === 1) {
              let formData = new FormData();
              const myFile = await modelViewer.current.toBlob();
              formData.append("file", myFile);
              formData.fileType = "image/png";
              projectPicture(
                formData,
                Cookies.get("projectId"),
                Cookies.get("modelId"),
                Cookies.get("token"),
              )
                .then((res) => {
                  const token = Cookies.get("token");
                  // encodeURIComponent
                  const encodeProjectId = encodeURIComponent(Cookies.get("projectId"));
                  const encodeModelsId = encodeURIComponent(Cookies.get("modelId"));
                  const encodeToken = encodeURIComponent(token);
                  const encodeisPublish = encodeURIComponent(Cookies.get("isPublish"));
                  const lang = Cookies.get("lang");
                  window.open(
                    `${SERVER.EDITOR_URL}?projectId=${encodeProjectId}&modelsId=${encodeModelsId}&token=${encodeToken}&isPublish=${encodeisPublish}&lang=${lang}`,
                    "_self",
                  );
                  setLoaderThreeJs(false);
                  setStartUpload(false);
                })
                .catch((err) => {
                  setLoaderThreeJs(false);
                  setStartUpload(false);
                  setIsUpload(false);
                  setFileName("");
                  handleErrorResponse("type22");
                });
            }
          });
        })
        .catch((err) => {
          handleErrorResponse("type22");
          setLoaderThreeJs(false);
          setStartUpload(false);
        });
    } else {
      const handleUploadBar = (progress) => {
        if (progress > 96) {
          setUploadProgress(96);
        } else {
          setUploadProgress(progress);
        }
      };
      // 初始化模型屬性
      let initModel = {
        glbName: "",
        glbSrc: "",
        gltfName: "",
        gltfSrc: "",
        splatSrc: "",
        splatName: "",
        usdzName: "",
        usdzSrc: "",
        viewModeSwitch: true,
      };
      updateModel(
        initModel,
        Cookies.get("token"),
        Cookies.get("projectId"),
        Cookies.get("modelId"),
      );
      // 上傳模型(Gltf/Glb)
      modelUpload(
        fileData,
        Cookies.get("token"),
        Cookies.get("projectId"),
        Cookies.get("modelId"),
        fileType,
        handleUploadBar,
      )
        .then((res) => {
          let body = {
            description: "",
            hashTags: [],
            id: Cookies.get("projectId"),
            name: fileName,
          };
          // 修改專案
          reviseProject(body, Cookies.get("token"))
            .then(() => {
              setModleFile(res.fileDownloadUri);
              modelViewer.current.addEventListener("progress", async (e) => {
                const getProgress = e.detail.totalProgress;
                if (getProgress === 1) {
                  let formData = new FormData();
                  const myFile =
                    fileType === "splat" || fileType === "ksplat"
                      ? dataURLtoBlob(threeThumbnail)
                      : await modelViewer.current.toBlob();
                  formData.append("file", myFile);
                  formData.fileType = "image/png";
                  projectPicture(
                    formData,
                    Cookies.get("projectId"),
                    Cookies.get("modelId"),
                    Cookies.get("token"),
                  )
                    .then((res) => {
                      navigate({
                        pathname: "/projectInfo",
                        search: `?mode=create&fileName=${fileName}`,
                      }); //fileName
                      setLoaderThreeJs(false);
                      setStartUpload(false);
                      if (window.location.href.indexOf("fileName=") > -1) {
                        location.reload();
                      }
                    })
                    .catch((err) => {
                      setLoaderThreeJs(false);
                      setStartUpload(false);
                      setIsUpload(false);
                      setFileName("");
                      handleErrorResponse("type22");
                    });
                }
              });
            })
            .catch((err) => {
              setLoaderThreeJs(false);
              setStartUpload(false);
              setIsUpload(false);
              setFileName("");
              handleErrorResponse("type22");
            });

          // navigate({
          //     pathname: '/projectInfo',
          //     search: `?mode=create&fileName=${fileName}`,
          // }); //fileName
        })
        .catch((err) => {
          setLoaderThreeJs(false);
          setStartUpload(false);
          setIsUpload(false);
          setFileName("");
          handleErrorResponse("type22");
        });
    }
  };

  useEffect(() => {
    if (threeThumbnail) {
      startUploadEvent();
    }
  }, [threeThumbnail]);

  const clearFileEvent = (e) => {
    e.preventDefault();
    setFlieModel(null);
    setStartUpload(false);
    setIsUpload(false);
    if (window.location.search === "") {
      deleteProject(Cookies.get("projectId"), Cookies.get("token")).then((res) => {
        navigate("/chooseType");
      });
    } else if (
      window.location.href.indexOf("/upload") > -1 &&
      window.location.href.indexOf("create=true") > -1 &&
      window.location.href.indexOf("useMaterialLab=true") > -1
    ) {
      // 第一次創建專案，預設進入素材庫，關閉時返回 CardList
      deleteProject(Cookies.get("projectId"), Cookies.get("token")).then((res) => {
        navigate("/chooseType");
      });
      return;
    } else if (window.location.href.indexOf("?fileName=") > -1) {
      history.back();
    } else if (window.location.search !== "") {
      deleteModel(Cookies.get("token"), Cookies.get("projectId"), Cookies.get("modelId")).then(
        () => {
          getProject(Cookies.get("token"), Cookies.get("projectId")).then((res) => {
            const token = Cookies.get("token");
            // encodeURIComponent
            const lang = encodeURIComponent(Cookies.get("lang"));
            const encodeProjectId = encodeURIComponent(Cookies.get("projectId"));
            const encodeModelsId = encodeURIComponent(res.project.editors[0].models[0].id);
            const encodeToken = encodeURIComponent(token);
            const encodeisPublish = encodeURIComponent(res.project.editors[0].publicSwitch);
            window.open(
              `${SERVER.EDITOR_URL}/?projectId=${encodeProjectId}&modelsId=${encodeModelsId}&token=${encodeToken}&isPublish=${encodeisPublish}&lang=${lang}`,
              "_self",
            );
          });
        },
      );
    }
  };

  const handleUploadOption = (e) => {
    setUploadOption(e);
  };

  const handleOpenIframe = (item) => {
    setMaterialIframe(item);
    setOpenIframe(true);
  };

  const handleCloseIframe = () => {
    setOpenIframe(false);
  };

  const handleUseMaterialLib = (item) => {
    if (
      urlParams.get("projectId") &&
      urlParams.get("modelId") &&
      urlParams.get("token") &&
      urlParams.get("isPublish")
    ) {
      useMaterialLib(
        Cookies.get("token"),
        item.id,
        Cookies.get("projectId"),
        Cookies.get("modelId"),
      )
        .then(() => {
          // 修改模型屬性 (viewModeSwitch)
          updateModel(
            { viewModeSwitch: true },
            Cookies.get("token"),
            Cookies.get("projectId"),
            Cookies.get("modelId"),
          );
        })
        .then(() => {
          const token = Cookies.get("token");
          // encodeURIComponent
          const encodeProjectId = encodeURIComponent(Cookies.get("projectId"));
          const encodeModelsId = encodeURIComponent(Cookies.get("modelId"));
          const encodeToken = encodeURIComponent(token);
          const encodeisPublish = encodeURIComponent(Cookies.get("isPublish"));
          const lang = Cookies.get("lang");
          window.open(
            `${SERVER.EDITOR_URL}?projectId=${encodeProjectId}&modelsId=${encodeModelsId}&token=${encodeToken}&isPublish=${encodeisPublish}&lang=${lang}`,
            "_self",
          );
        })
        .catch((err) => {
          handleErrorResponse("type22");
          setStartUpload(false);
        });
    } else {
      useMaterialLib(
        Cookies.get("token"),
        item.id,
        Cookies.get("projectId"),
        Cookies.get("modelId"),
      )
        .then(() => {
          // 修改模型屬性 (viewModeSwitch)
          updateModel(
            { viewModeSwitch: true },
            Cookies.get("token"),
            Cookies.get("projectId"),
            Cookies.get("modelId"),
          );
        })
        .then(() => {
          navigate({
            pathname: "/projectInfo",
            search: `?mode=create&fileName=${item.name}&useMaterialLab=true`,
          });
        })
        .catch((err) => {
          setStartUpload(false);
          setIsUpload(false);
          setFileName("");
          handleErrorResponse("type22");
        });
    }
  };

  const MaterialLibListholder = () => {
    let inner = [];
    for (let i = 0; i < 12; i++) {
      inner.push(
        <div className="materialLib_List_holder_Card" key={i}>
          <div className="materialLib_List_holder_Card_Pic"></div>
          <div className="materialLib_List_holder_Card_Name"></div>
        </div>,
      );
    }
    return inner;
  };

  const handleOpenNameTootip = (name) => (element) => {
    if (name.length > 30) {
      let X = element.target.getBoundingClientRect().left;
      let Y = element.target.getBoundingClientRect().top + window.pageYOffset;
      tootipbox.current.style.left = X + "px";
      tootipbox.current.style.top = Y + "px";
      setNameTootip(name);
      setOpenNameTootip(true);
    }
  };

  const handlecloseNameTootip = () => {
    setOpenNameTootip(false);
  };

  const updateThumbnail = (thumbnail) => {
    setThreeThumbnail(thumbnail);
  };

  return (
    <StyledUpload>
      {modalSetting.show && (
        <ModalPopup
          modalConfig={modalConfig[modalSetting.type]}
          setModalSetting={setModalSetting}
          modalSetting={modalSetting}
        />
      )}
      {!startUpload && (
        <TopBar
          title={t("uploadTitle")}
          isReload={Boolean(getFileName)}
          reloadTitle={getFileName}
          isOther={otherModelTitle}
          noColor={true}
        />
      )}
      {!startUpload && (
        <div className="uploadOption" style={{ top: Boolean(getFileName) ? "56px" : "0px" }}>
          <div className="uploadOption_container">
            <div
              className={UploadOption ? "selet selet_3D isActive" : "selet selet_3D"}
              onClick={() => handleUploadOption(true)}
            >
              <span>{t("uploadTitle")}</span>
            </div>
            <div
              className={
                !UploadOption ? "selet select_MaterialLib isActive" : "selet select_MaterialLib"
              }
              onClick={() => handleUploadOption(false)}
            >
              <span>{t("useMaterialLib")}</span>
            </div>
          </div>
        </div>
      )}
      {!UploadOption && (
        <div className="materialLib_List">
          {materialLib.map((item, idx) => {
            return (
              <div
                className="materialLib_Card"
                key={item.id}
                onClick={() => handleOpenIframe(item)}
              >
                <div className="materialLib_Card_Pic">
                  <div className="materialLib_Card_Pic_Mask">
                    <div
                      className="useMaterial"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUseMaterialLib(item);
                      }}
                    >
                      {t("useMaterialObject")}
                    </div>
                    <div className="previewMaterial" onClick={() => handleOpenIframe(item)}>
                      {t("preview")}
                    </div>
                  </div>
                  <img src={item.icom} alt="item-icon" />
                </div>
                <div
                  className="materialLib_Card_Name"
                  onMouseEnter={handleOpenNameTootip(item.name)}
                  onMouseOut={handlecloseNameTootip}
                >
                  {item.name}
                </div>
              </div>
            );
          })}
          <div
            className="tootipbox"
            ref={tootipbox}
            style={{ display: openNameTootip ? "block" : "none" }}
          >
            <div className="triangle"></div>
            <div className="tootip">{nameTootip}</div>
          </div>
        </div>
      )}
      {!UploadOption && openMaterialPicHolder && (
        <div className="materialLib_List_holder">
          <MaterialLibListholder />
        </div>
      )}
      <div className="materialLib_ifram" style={{ display: openIframe ? "block" : "none" }}>
        <div className="materialLib_ifram_popup">
          <div className="closeBtn" onClick={handleCloseIframe}>
            <img src={closeIcon} />
          </div>
          <div className="main">
            {showModelErr && (
              <ModalPopup
                modalConfig={modalConfig[modalSetting.type]}
                setModalSetting={setModalSetting}
                modalSetting={modalSetting}
              />
            )}
            {modelLoadingProgress < 1 && (
              <div className="loadingProgressContainer flexCenter flexY">
                <div className="progressText">iStaging</div>
                <div className="progressBarPanel">
                  <ProgressBar now={modelLoadingProgress} min={0} max={1} />
                </div>
              </div>
            )}
            <model-viewer
              id="myModelViewer"
              camera-controls
              src={materialIframe?.glbgltf}
            ></model-viewer>
          </div>
          <div className="name">{materialIframe?.name}</div>
          <div className="useMaterial" onClick={() => handleUseMaterialLib(materialIframe)}>
            {t("useMaterialObject")}
          </div>
          {materialIframe?.name.length > 30 && (
            <div className="tootipbox">
              <div className="triangle"></div>
              <div className="tootip">{materialIframe?.name}</div>
            </div>
          )}
        </div>
      </div>
      {UploadOption && (
        <div className="inner">
          <div className="block">
            {UploadOption && (
              <Dropzone onDrop={acceptedFiles}>
                {({ getRootProps, getInputProps }) => (
                  <section className="fileDropCon">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className="fileDrop"></p>
                    </div>
                  </section>
                )}
              </Dropzone>
            )}
            {!startUpload && UploadOption && (
              <div>
                <div className="info">
                  <div className={isUpload ? "file" : "file noFile"}>
                    <img className="fileIcon" src={fileUploadIcon} alt="" loading="lazy" />
                    {isUpload && <div className="tt">{fileName}</div>}
                  </div>
                  <div className="title">{t("uploadDescription1")}</div>
                  <div className="word">
                    {t("uploadDescription2")}
                    <br />
                    <span>{t("uploadDescription3")}</span>
                  </div>
                </div>
                <div className="btnContainer">
                  <a href="#" onClick={clearFileEvent} className="canelBtn">
                    {t("cancel")}
                  </a>
                  <a
                    href="#"
                    onClick={startUploadEvent}
                    className={isUpload ? "uploadBtn" : "uploadBtn noFile"}
                  >
                    {t("uploadObject")}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {startUpload && <LoadingBar progress={UploadProgress} fileName={fileName} />}
      <model-viewer id="modelViewer" ref={modelViewer} src={modelFile}></model-viewer>
      {loaderThreeJs && (
        <ThreeJs fileModel={fileModel} fileType={fileType} onThumbnailReceived={updateThumbnail} />
      )}
    </StyledUpload>
  );
};

export default Upload;
