import styled from "styled-components";

export const StyledBanner = styled.div`
  position: fixed;
  width: 100%;
  bottom: 15px;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  .banner {
    display: block;
    width: 100%;
    max-width: 1200px;
    position: relative;

    &_close_button {
      font-size: 28px;
      color: #ffffff;
      position: absolute;
      top: 0;
      right: 0;
    }

    &_close_button:hover {
      color: rgba(255, 255, 255, 0.8);
    }

    &_image {
      width: 100%;
      object-fit: contain;
    }
  }
`;
