import styled from "styled-components";

export const StyledCardListPOP = styled.div`
  width: 178px;
  border-radius: 3px;
  background-color: #fff;
  position: absolute;
  top: 28px;
  left: calc((-178px + 24px) / 2);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  ul {
    li {
      text-align: left;
      padding: 10px;
      font-size: 14px;
      > svg {
        position: relative;
        top: 3px;
        fill: #333;
        margin-right: 7px;
        font-size: 16px;
        width: 18px;
      }
      &:hover {
        color: #ff744e;
        svg {
          fill: #ff744e;
            g path {
                stroke: #ff744e;
            }
        }
      }
      &:first-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
      &:last-child {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        color: #f5314d;
        svg {
          fill: #f5314d;
        }
      }
    }
    .loadingMask {
      position: fixed;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100vw;
      height: 100vh;
      z-index: 9;
    }
  }
  .menuDisabled {
    color: #cccccc !important;
    svg {
      fill: #cccccc !important;
    }
    &:not(:last-child):hover {
      color: #cccccc;
      svg {
        fill: #cccccc;
      }
    }
  }
`;
