import * as GaussianSplats3D from "@mkkellogg/gaussian-splats-3d";
import * as THREE from "three";

export default class CustomGS3DViewer extends GaussianSplats3D.Viewer {
  private _isFadeInComplete: boolean = false;

  private _thumbnail: string;

  private _onThumbnailChange: (newThumbnail: string) => void;

  get thumbnail() {
    return this._thumbnail;
  }

  set thumbnail(value) {
    this._thumbnail = value;
    this._onThumbnailChange(value);
  }

  onThumbnailChange(callback) {
    this._onThumbnailChange = callback;
  }

  constructor(options = {}) {
    super(options);
    this._thumbnail = "";
    this._onThumbnailChange = () => {};
  }

  render = (function () {
    return function () {
      if (!this.initialized || !this.splatRenderReady) return;

      const hasRenderables = (threeScene) => {
        for (let child of threeScene.children) {
          if (child.visible) return true;
        }
        return false;
      };

      const savedAuoClear = this.renderer.autoClear;
      if (hasRenderables(this.threeScene)) {
        this.renderer.render(this.threeScene, this.camera);
        this.renderer.autoClear = false;
      }
      this.renderer.render(this.splatMesh, this.camera);
      if (!this.splatMesh.visibleRegionChanging && !this._isFadeInComplete) {
        this.thumbnail = this.renderer.domElement.toDataURL("image/png");
        this._isFadeInComplete = true;
      }
      this.renderer.autoClear = false;
      if (this.sceneHelper.getFocusMarkerOpacity() > 0.0)
        this.renderer.render(this.sceneHelper.focusMarker, this.camera);
      if (this.showControlPlane) this.renderer.render(this.sceneHelper.controlPlane, this.camera);
      this.renderer.autoClear = savedAuoClear;
    };
  })();

  updateFocusMarker = (): void => {};

  updateCameraTransition = (function () {
    let toPreviousTarget = new THREE.Vector3();
    let toNextTarget = new THREE.Vector3();
    return function (currentTime) {
      if (this.transitioningCameraTarget) {
        toPreviousTarget.copy(this.previousCameraTarget).sub(this.camera.position).normalize();
        toNextTarget.copy(this.nextCameraTarget).sub(this.camera.position).normalize();
        const rotationAngle = Math.acos(toPreviousTarget.dot(toNextTarget));
        const rotationSpeed = (rotationAngle / (Math.PI / 3)) * 0.65 + 0.3;
        const t =
          (rotationSpeed / rotationAngle) * (currentTime - this.transitioningCameraTargetStartTime);
        if (t >= 1.0) {
          this.transitioningCameraTarget = false;
        }
      }
    };
  })();
}
