import styled, { css } from "styled-components";

export const StyledChooseType = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;

  .close_button {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  .page_title {
    font-weight: 500;
    font-size: 34px;
    line-height: 49px;
    letter-spacing: 0.01em;
    color: #262626;
    padding-top: 140px;
    margin-bottom: 100px;
  }

  .page_content {
    display: flex;
    align-items: center;
    justify-content: center;

    .type_section {
      width: 436px;
      height: 392px;
      padding: 40px 48px 48px 48px;

      .object_icon {
        width: 48px;
        height: 48px;
        margin-bottom: 24px;
      }

      .object_description {
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        letter-spacing: 0.01em;
        color: #262626;
        margin-bottom: 16px;

        .primary_highlight {
          color: #e01d62;
        }

        .secondary_highlight {
          color: #ff7043;
        }
      }

      .support_format {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.01em;
        color: #595959;
      }

      button {
        margin: 48px auto;
      }
    }

    .divider_section {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 16px;
      margin: 0px 72px;

      span {
        display: block;
        width: 1px;
        height: 162.5px;
        background: #bfbfbf;
      }

      div {
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        letter-spacing: 0.01em;
        color: #595959;
      }
    }
  }

  .bsRotate {
    animation: 2s rotateMove infinite linear;
  }

  @keyframes rotateMove {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;
