import styled from "styled-components";

export const StyledCookieBanner = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 99999999;
  width: 100%;
  padding: 20px 32px;
  background: #ffffff;
  box-shadow:
    0px -1px 2px 0px rgba(0, 0, 0, 0.15),
    0px -4px 8px 3px rgba(0, 0, 0, 0.1);
  display: ${({ cookieBanner }) => (cookieBanner ? "flex" : "none")};
  flex-direction: row;
  column-gap: 64px;
  align-items: center;
  justify-content: space-between;

  @media all and (max-width: 576px) {
    flex-direction: column;
    row-gap: 20px;
  }

  .cookie_text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #333333;
    text-align: left;

    > a {
      font-weight: 500;
      line-height: 24px;
      text-decoration-line: underline;
      color: #376fd5;
      cursor: pointer;
    }
  }

  .cookie_button {
    padding: 8px 16px;
    border-radius: 8px;
    background: #ec0974;
    font-weight: 500;
    line-height: 24px;
    color: #ffffff;
    cursor: pointer;

    @media all and (max-width: 576px) {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
`;
