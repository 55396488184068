import axiosApi, { setHeader } from "api/root.api";

export const getFilterProjectList = async (
  size,
  page,
  filterCategory,
  filterStatus,
  mainSortCategory,
  mainSortStatus,
) => {
  try {
    const version = "v4";
    let urlEnd;
    if (filterCategory !== "All" && filterStatus === "All") {
      urlEnd = `/editor/project/${version}/list?size=${size}&page=${page}&projectType=${filterCategory}`;
    } else if (filterCategory === "All" && filterStatus !== "All") {
      urlEnd = `/editor/project/${version}/list?size=${size}&page=${page}&projectPublicState=${filterStatus}`;
    } else if (filterCategory !== "All" && filterStatus !== "All") {
      urlEnd = `/editor/project/${version}/list?size=${size}&page=${page}&projectType=${filterCategory}&projectPublicState=${filterStatus}`;
    } else {
      urlEnd = `/editor/project/${version}/list?size=${size}&page=${page}`;
    }

    urlEnd += `&sort=${mainSortCategory},id,${mainSortStatus}`;

    const response = await axiosApi.get(urlEnd);
    let cardListDetail = response.data.cardListDetail.content;
    response.data.numberOfElements = response.data.cardListDetail.numberOfElements;
    response.data.totalElements = response.data.cardListDetail.totalElements;
    response.data.cardListDetail = cardListDetail;
    const result = response.data;
    return result;
  } catch (error) {
    return {
      result: false,
      filterStatus: "failed",
    };
  }
};
