import styled from "styled-components";

export const StyledCreate = styled.div`
  .empty_container {
    width: 100%;
    height: calc(100vh - 160px);
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .empty_wrapper {
      margin: 240px auto;

      > svg {
        width: 282px;
        height: 232px;
      }

      > div {
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        color: #8c8c8c;
        margin-top: 8px;
      }

      > button {
        margin: 64px auto;
      }
    }
  }
`;
