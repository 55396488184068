import React, { useState, useEffect } from "react";

//styled
import { StyledMoneyStatus } from "./StyledMoneyStatus";

// images
import Progressing from "./images/progressing.svg";
// import Basic from './images/basic.svg';
import { ReactComponent as Success } from "./images/success.svg";
import { ReactComponent as Error500 } from "./images/error-500.svg";
import { ReactComponent as Error002 } from "./images/error.svg";

//i18n
import { useTranslation } from "react-i18next";

const MoneyStatus = ({ status, setApiStatus }) => {
  const { t } = useTranslation();

  const backCardEvent = () => {
    setApiStatus("null");
  };

  return (
    <StyledMoneyStatus>
      {/* progressing */}
      {status === "progressing" && (
        <div>
          <div className="loading-container">
            <div className="loading-circle"></div>
            <img src={Progressing} className="loading-icon" alt="progressing-icon" />
          </div>
          <div className="other-pages-title">{t("message.progressing")}</div>
        </div>
      )}

      {/* success */}
      {status === "success" && (
        <div>
          <Success className="iconSize" />
          <div className="other-pages-title">{t("message.success")}</div>
        </div>
      )}

      {/* error 500 */}
      {status === "error500" && (
        <div>
          <Error500 className="iconSize" />
          <div className="other-pages-title">{t("message.error500Title")}</div>
          <div className="other-pages-subtitle">
            {t("message.error500Subtitle")}{" "}
            <span style={{ color: "#54C68F" }}>hello@istaging.com</span>。
            <br /> {t("message.errorCode500")}
          </div>
          <button onClick={backCardEvent} className="retry-button">
            {t("common.retryButton")}
          </button>
        </div>
      )}

      {/* error 002 */}
      {status === "error 002" && (
        <div>
          <Error002 className="iconSize" />
          <div className="other-pages-title">{t("message.error002Title")}</div>
          <div className="other-pages-subtitle">
            {t("message.error002Subtitle")}{" "}
            <span style={{ color: "#54C68F" }}>hello@istaging.com</span>。
            <br /> {t("message.errorCode002")}
          </div>
          <button onClick={backCardEvent} className="retry-button">
            {t("common.retryButton")}
          </button>
        </div>
      )}

      {/* error 008 */}
      {status === "error 008" && (
        <div>
          <Error002 className="iconSize" />
          <div className="other-pages-title">{t("message.error008Title")}</div>
          <div className="other-pages-subtitle">
            {t("message.error008Subtitle")}{" "}
            <span style={{ color: "#54C68F" }}>hello@istaging.com</span>。
            <br /> {t("message.errorCode008")}
          </div>
          <button onClick={backCardEvent} className="retry-button">
            {t("common.retryButton")}
          </button>
        </div>
      )}

      {/* basic */}
      {/* <div>
                <img src={Basic} width={72} height={72} alt="basic-icon" />
                <div className='other-pages-title'>{t('message.basicTitle')}</div>
                <div className='other-pages-subtitle'>
                    {t('message.basicSubtitle')} <span style={{ color: '#54C68F' }}>hello@istaging.com</span>
                </div>
                <button className='retry-button' onClick={backCardEvent}>{t('common.goToPlatform')}</button>
            </div> */}
    </StyledMoneyStatus>
  );
};

export default MoneyStatus;
