import styled from "styled-components";

export const StyledAbnormalPopup = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  z-index: 999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .abnormal {
    &_popup {
      width: 560px;
      background: #ffffff;
      border-radius: 12px;
      padding: 36px 40px;

      &_title {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.87);
        margin-top: 36px;
        margin-bottom: 4px;
      }

      &_subtitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: rgba(0, 0, 0, 0.6);
      }

      &_buttons {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 82px auto 0 auto;
      }
    }
  }
`;
