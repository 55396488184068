import styled from "styled-components";
import refresh from "./icons/refresh.svg";

export const StyledProjectInfoContainer = styled.div`
  width: 100vw;
  height: 100%;
  background: #f2f2f2;
  position: relative;

  .projectInfoPanel {
    margin-top: 40px;
    width: 1020px;
    height: 100%;
  }

  .projectTopPanel {
    margin-bottom: 126px;
    width: 100%;
    height: 700px;
  }

  .projectBottomPanel {
    width: 100%;
    height: auto;
    background-color: #fff;
    padding: 15px;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.2);
    position: fixed;
    left: 0;
    bottom: 0;
    .inner {
      max-width: 1020px;
      margin: 0 auto;
    }
  }

  .projectStatusPanel {
    width: 328px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
  }

  .modelImage {
    margin-bottom: 30px;
    width: 280px;
    height: 182px;
    background: #f2f2f2;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    .picholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #f2f2f2;
      border-radius: 8px;
    }
    img {
      width: 280px;
      height: 182px;
      object-fit: cover;
    }
    svg {
      width: 80px;
      height: 80px;
      path {
        fill: #c5c5c5;
      }
    }
  }

  .detailPanel {
    width: 280px;
    height: 24px;
    margin-bottom: 20px;

    .detailIcon {
      margin-right: 8px;
      width: 17px;
    }

    .detailText {
      font-size: 14px;
    }

    .detailType {
      padding: 1px 16px;
      background: #ff5e8f;
      border-radius: 24px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin-left: auto;
    }

    .detailContent {
      margin-left: auto;
      font-size: 14px;
    }

    .draftText {
      font-size: 12px;
      width: 55px;
      height: 20px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 24px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .publicTrue {
      background: #2bb8af;
    }

    .createdDate,
    .lastPublish,
    .lastEdit {
      color: #e01d62;
      font-size: 14px;
      font-weight: 500;
    }

    .nullDate {
      color: rgba(0, 0, 0, 0.2) !important;
    }
  }

  .objectCode {
    display: flex;
    align-items: center;
    gap: 28px;
    border-radius: 5px;
    background-color: #f4f4f4;
    padding: 5px 5px 6px 14px;
    margin-left: auto;

    .codeText {
      color: #000;
      text-align: center;
      font-family: Barlow;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
    }

    .codeIcon {
      display: flex;
      width: 20px;
      height: 20px;
      margin: 6px;
      mask-image: url(${refresh});
      mask-size: cover;
      background-color: black;
    }

    .refreshCodeButton {
      background-color: transparent;
      cursor: pointer;

      &:hover .codeIcon {
        background-color: #e1609b;
        opacity: 0.8;
      }

      &:active .codeIcon {
        background-color: #e1609b;
        opacity: 1;
      }
    }
  }

  .projectFormPanel {
    width: 668px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
  }

  .projectNamePanel {
    margin-top: 26px;
    margin-bottom: 42px;
    padding: 0px 24px;
    width: 100%;
    height: 80px;
    box-sizing: border-box;
    position: relative;
    .projectLabelPanel {
      margin-bottom: 10px;
      width: 100%;
      height: 20px;

      .projectLabelText {
        font-size: 14px;
      }

      span {
        color: red;
      }
    }
    .projectLableTextCounts {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      position: absolute;
      right: 30px;
      top: 5px;
    }

    .projectNameInput {
      font-family: inherit;
      padding: 8px 16px;
      width: 100%;
      height: 40px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      font-size: 14px;
      font-weight: 500;
      box-sizing: border-box;
      outline-color: #ff744e;
      outline-width: 1px;
      &:hover {
        border: 1px solid #ff744e;
        border-radius: 2px;
      }
      &:focus {
        border: 1px solid #ff744e;
        box-shadow: 0px 0px 0px 4px rgba(255, 116, 78, 0.15);
        border-radius: 2px;
      }
      &:focus + .projectLableTextCounts {
        color: #ff744e;
      }
    }
    .projectNameInputErr {
      border: 1px solid #f5314d;
      outline-color: #f5314d;
      &:focus {
        border: 1px solid #f5314d;
        box-shadow: 0px 0px 0px 4px rgba(245, 49, 77, 0.15);
        border-radius: 2px;
      }
    }

    .projectNameInputErr + .projectLableTextCounts {
      color: #f5314d;
    }

    .projectNameInputError {
      font-size: 12px;
      color: #f5314d;
      padding-left: 10px;
      padding-top: 5px;
    }
  }

  .projectDescPanel {
    width: 100%;
    height: 122px;
    padding: 0px 24px;
    box-sizing: border-box;
    position: relative;
    .descLabel {
      margin-bottom: 10px;
      width: 100%;
      text-align: left;
      font-size: 14px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }

    .projectDesc {
      font-family: inherit;
      resize: none;
      min-width: 100%;
      max-width: 618px;
      height: 100px;
      max-width: 100px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      padding: 8px 16px;
      box-sizing: border-box;
      outline-color: #ff744e;
      outline-width: 1px;
      &:hover {
        border: 1px solid #ff744e;
        border-radius: 2px;
      }
      &:focus {
        border: 1px solid #ff744e;
        box-shadow: 0px 0px 0px 4px rgba(255, 116, 78, 0.15);
        border-radius: 2px;
      }
      &:focus + .descriptLength {
        color: #ff744e;
      }
    }
    .descriptLength {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      position: absolute;
      right: 30px;
      top: 5px;
    }
  }

  .projectInfoBtn {
    width: 122px;
    height: 48px;
    border-radius: 8px;
    &:hover {
      cursor: pointer;
    }
  }
  .cancelBtn {
    width: 80px;
  }

  .lds-dual-ring {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }

  .lds-dual-ring:after {
    position: relative;
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .reverseBtn {
    flex-direction: row-reverse;
  }

  .reuploadBtn {
    background: #f2f2f2;
    color: #666666;
    text-decoration: none;
    &:hover {
      background: #e0e0e0;
    }
  }

  .saveBtnPanel {
    .onlySave {
      padding: 12px 24px;
      border-radius: 8px;
    }

    .saveRedirect {
      height: 48px;
      width: auto;
      padding: 0px 22px;
    }

    .editOnlySave,
    .createSaveRedirect {
      background: #ed2975;
      color: #fff;
      &:hover {
        background-color: #f2539d;
      }
    }

    .editSaveRedirect,
    .createOnlySave {
      background: #fff;
      color: #ed0973;
      border: 1px solid #ed0973;
      &:hover {
        background-color: #f2539d;
        border: 1px solid #f2539d;
        color: #fff;
      }
    }

    > :nth-child(1) {
      margin-right: ${(props) => (props.mode === "edit" ? "0px" : "24px")};
      /* background: #fff;
            color: #ED2975;
            border: 1px solid #ED2975; */
    }

    > :nth-child(2) {
      margin-right: ${(props) => (props.mode === "edit" ? "24px" : "0px")};
    }

    .editOnlySaveSpinner:after,
    .createSaveRedirectSpinner:after {
      border: 2px solid #fff !important;
      border-color: #fff transparent #fff transparent !important;
    }

    .createOnlySaveSpinner:after,
    .editSaveRedirectSpinner:after {
      border: 2px solid #ed2975 !important;
      border-color: #ed2975 transparent #ed2975 transparent !important;
    }
  }

  .pubCtrl {
    padding-top: 50px;
    a {
      width: 296px;
      height: 32px;
      display: block;
      margin-bottom: 15px;
      border-radius: 4px;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
      text-decoration: none;
      &:first-child {
        background: #e01d62;
        color: #fff;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .disableEditOnlySaveBtn,
  .disableCreateOnlySaveBtn {
    background: #fab5d5 !important;
    border: 1px solid #fab5d5 !important;
    color: #fff !important;
    border-radius: 8px;

    &:hover {
      cursor: not-allowed;
    }
  }

  .disableEditSaveRedirectBtn,
  .disableCreateSaveRedirectBtn {
    background: #ffffff !important;
    border: 1px solid #fab5d5 !important;
    color: #fab5d5 !important;
    border-radius: 8px;

    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const StyledPanelNotch = styled.div`
  margin-bottom: 20px;
  width: 100%;
  height: 8px;
  background: #ff5e8f;
  border-radius: 8px 8px 0px 0px;
`;
