import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

//env
import SERVER from "constants/server";
import HARDCODE from "constants/hardcode";

//api
import { loginPost } from "api/login/login.api";
import { loginGet } from "api/loginInfo/loginInfo.api";
import { visitGet } from "api/visit/visit.api";
import { currentInfo } from "api/currentInfo/currentInfo.api";

//react icon
import { AiFillEye, AiOutlineCheck, AiFillEyeInvisible } from "react-icons/ai";
import { BsFillBagFill, BsArrowRepeat } from "react-icons/bs";
import { FaGlobe, FaUserAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

//image
import bg from "./images/bg.svg";
import logo from "./images/logo.svg";

//styled
import { StyledRegister } from "./styledRegister";

//plugin
import Cookies from "js-cookie";
import axios from "axios";

//i18n
import { useTranslation, Trans } from "react-i18next";

// lang
import { getOfficialWebsiteLang } from "utils/language";

const Register = () => {
  const navigate = useNavigate();
  const usernameInput = useRef();
  const accountPassword = useRef();
  const accountEmail = useRef();
  const accountRePassword = useRef();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let isApi = 0;

  const [usernameErrStatus, setUsernameErrStatus] = useState(false);
  const [emailErrStatus, setEmailErrStatus] = useState(false);
  const [passwordErrStatus, setPasswordErrStatus] = useState(false);
  const [rePasswordErrStatus, setRePasswordErrStatus] = useState(false);
  const [showPassword, setShowPass] = useState({
    showPassword: false,
    showRePassword: false,
  });
  const [apiStatus, setApiStatus] = useState(0);
  const [apiFail, setApiFail] = useState(false);

  let emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;

  const [userName, setUserName] = useState("");
  const { t, i18n } = useTranslation();
  const termsConditions = t("termsConditions");
  const privacyPolicy = t("privacyPolicy");

  if (urlParams.get("lang")) {
    Cookies.set("lang", urlParams.get("lang"));
  }

  const userNameChange = (e) => {
    if (e.target.value.indexOf(" ") > -1) return setApiFail("hasSpace");
    setUserName(e.target.value);
    setUsernameErrStatus(false);
    isApi = 0;
    setApiFail(false);
  };

  const [email, setEmail] = useState("");

  const emailChange = (e) => {
    if (e.target.value.indexOf(" ") > -1) return setApiFail("hasSpace");
    setEmail(e.target.value);
    setEmailErrStatus(false);
    isApi = 0;
    setApiFail(false);
  };

  const [password, setPassword] = useState("");

  const passwordChange = (e) => {
    if (e.target.value.indexOf(" ") > -1) return setApiFail("hasSpace");
    setPassword(e.target.value);
    setPasswordErrStatus(false);
    isApi = 0;
    setApiFail(false);
  };

  const [rePassword, setRePassword] = useState("");

  const rePasswordChange = (e) => {
    if (e.target.value.indexOf(" ") > -1) return setApiFail("hasSpace");
    setRePassword(e.target.value);
    setRePasswordErrStatus(false);
    isApi = 0;
    setApiFail(false);
  };

  const showPasswordEvent = () => {
    setShowPass({ ...showPassword, showPassword: !showPassword.showPassword });
  };

  const showRePasswordEvent = () => {
    setShowPass({ ...showPassword, showRePassword: !showPassword.showRePassword });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (userName.length < 4) {
      setUsernameErrStatus(true);
      isApi = 1;
    }
    if (email.search(emailRule) == -1) {
      setEmailErrStatus(true);
      isApi = 1;
    }
    if (password.length < 6) {
      setPasswordErrStatus(true);
      isApi = 1;
    }
    if (password !== rePassword || rePassword === "") {
      setRePasswordErrStatus(true);
      isApi = 1;
    }

    if (isApi === 1) {
      return;
    }

    let sendData = {
      username: userName,
      password: btoa(password),
      email: email,
      stripeToken: "",
      packageId: HARDCODE.FREE_PACKAGE_ID,
      productId: HARDCODE.PRODUCT_ID,
      expiredAt: "2032-01-01T00:00:00.000Z",
    };
    setApiStatus(1);
    try {
      const accoutEvent = await axios({
        method: "post",
        data: sendData,
        url: `${SERVER.PLATFORM_URL}/public/v1/signup/subscribe`,
      });
      setApiStatus(2);
      const loginInfo = {
        username: sendData.username,
        productKey: "test",
        password: atob(sendData.password),
      };
      const limits = await loginPost(loginInfo);
      const [projectInfo, userInfo, visitData] = await Promise.all([
        loginGet(),
        currentInfo(),
        visitGet(),
      ]);
      window.open(window.location.origin, "_self");
    } catch (err) {
      setApiStatus(0);
      setApiFail(true);
    }
  };

  useEffect(() => {
    usernameInput.current.focus();
    window.history.pushState({}, 0, window.location.origin + window.location.pathname);
  }, []);

  const [langList, setLangList] = useState(false);
  const [currentLang, setCurrentLang] = useState(Cookies.get("lang"));

  const openLangListEvent = (e) => {
    e.stopPropagation();
    setLangList(!langList);
  };

  const lanEvent = (lang) => {
    i18n.changeLanguage(lang);
    Cookies.set("lang", lang);
    setLangList(false);
    setCurrentLang(lang);
    usernameInput.current.focus();
  };

  useEffect(() => {
    document.addEventListener("click", (e) => setLangList(false));
  }, []);

  return (
    <StyledRegister>
      <div className="whiteBg">
        <a href={`${SERVER.AR_MAKER}/${getOfficialWebsiteLang(currentLang)}`} target="_blank" rel="noopener">
          <img src={logo} className="logo" alt="ARmaker Logo" />
        </a>

        {apiFail && (
          <div className="inputErrorMsg flexCenter">
            <span className="flexCenter">
              {apiFail === "hasSpace" ? t("cannotInputSpace") : t("signupFailed")}
            </span>
          </div>
        )}
        <h1>{t("signupEnterTitle")}</h1>

        <form className="formTable" autoComplete="off">
          <div className="accountInputPanel inputCon">
            <span>
              <FaUserAlt />
            </span>
            <input
              ref={usernameInput}
              name="username"
              className="inputs accountInput"
              value={userName}
              onChange={userNameChange}
              type="text"
              placeholder={t("content.userAccount")}
            />
            {usernameErrStatus && (
              <b>{userName.length === 0 ? t("mustFill") : t("atLeastFourletters")}</b>
            )}
          </div>

          <div className="mailInputPanel inputCon">
            <span>
              <MdEmail />
            </span>
            <input
              ref={accountEmail}
              name="email"
              className="inputs emailInput"
              value={email}
              onChange={emailChange}
              type="text"
              placeholder={t("content.userEmail")}
            />
            {emailErrStatus && <b>{email.length === 0 ? t("mustFill") : t("wrongEmail")}</b>}
          </div>

          <div className="passwordInputPanel inputCon">
            <span className="eye" onClick={showPasswordEvent}>
              {!showPassword.showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>
            <span>
              <BsFillBagFill />
            </span>
            <input
              ref={accountPassword}
              name="password"
              className="inputs passwordInput"
              value={password}
              onChange={passwordChange}
              placeholder={t("content.userPassword")}
              type={showPassword.showPassword ? "text" : "password"}
            />
            {passwordErrStatus && (
              <b>{password.length === 0 ? t("mustFill") : t("atLeastSixletters")}</b>
            )}
          </div>
          <div className="passwordInputPanel inputCon">
            <span className="eye" onClick={showRePasswordEvent}>
              {!showPassword.showRePassword ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>
            <span>
              <BsFillBagFill />
            </span>
            <input
              ref={accountRePassword}
              name="password"
              className="inputs passwordInput"
              value={rePassword}
              onChange={rePasswordChange}
              placeholder={t("content.enterPasswordAgain")}
              type={showPassword.showRePassword ? "text" : "password"}
            />
            {rePasswordErrStatus && (
              <b>{rePassword.length === 0 ? t("mustFill") : t("passwordNotMatch")}</b>
            )}
          </div>
        </form>
        <div className="checkout">
          <Trans i18nKey="signupAgreement">
            註冊帳號即表示您同意iStaging{" "}
            <a href={"https://www.istaging.com/" + i18n.language + "/terms/"} target="_blank">
              {{ termsConditions }}
            </a>{" "}
            與{" "}
            <a href={"https://www.istaging.com/" + i18n.language + "/privacy/"} target="_blank">
              {{ privacyPolicy }}
            </a>
          </Trans>
        </div>
        <button onClick={handleSubmit} className="button">
          {apiStatus === 1 ? (
            <BsArrowRepeat className="bsRotate" />
          ) : apiStatus === 2 ? (
            <AiOutlineCheck />
          ) : (
            ""
          )}
          {t("signupButton")}
        </button>
        <div className="say">
          {t("content.alreadyHaveAccount")} <Link to="./login">{t("content.loginNow")}</Link>
        </div>
        <div className="langBtn" onClick={openLangListEvent}>
          <FaGlobe className="globa" />
          {currentLang === "fr" ? "Français" : currentLang === "en" ? "English" : "繁體中文"}
        </div>
        {langList && (
          <ul className="langList">
            <li
              onClick={() => lanEvent("zh-tw")}
              className={currentLang === "zh-tw" ? "current" : ""}
            >
              繁體中文
              <AiOutlineCheck />
            </li>
            <li onClick={() => lanEvent("en")} className={currentLang === "en" ? "current" : ""}>
              English
              <AiOutlineCheck />
            </li>
            <li onClick={() => lanEvent("fr")} className={currentLang === "fr" ? "current" : ""}>
              Français
              <AiOutlineCheck />
            </li>
          </ul>
        )}
      </div>
      <img src={bg} className="bg" alt="" />
    </StyledRegister>
  );
};

export default Register;
