import { useEffect, useState, useRef } from "react";

// redux
import { useSelector, useDispatch } from "react-redux";
import { SET_NEW_TAG } from "redux/types";

// styles
import { StyledImage360 } from "./StyledImage360";

// components
import ScanEditorTag from "components/ScanEditorTag/ScanEditorTag";
import ScanViewerTag from "components/ScanViewerTag/ScanViewerTag";

// images
import { ReactComponent as Gesture } from "./images/gesture.svg";

const Image360 = ({ imageList, viewer, rate, tagNavbar, tags, currentTag }) => {
  const { scanSwitch, tagSwitch } = useSelector((store) => store.switch);
  const dispatch = useDispatch();

  // higher values make mouse less sensitive
  const pixelsPerDegree = 3;

  const [displayPanel, setDisplayPanel] = useState(0);

  const [images, setImages] = useState({
    dragging: false,
    startIndex: 0,
    dragStartIndex: 0,
  });

  const gestureElement = useRef();

  const handleMouseDown = (e) => {
    // console.log('mouse down 事件');
    if (gestureElement.current.style.display !== "none") {
      gestureElement.current.style.display = "none";
    }
    // 關閉所有標籤面板
    if (viewer || (tagNavbar && tagSwitch)) setDisplayPanel(null);
    setImages({
      ...images,
      dragging: true,
      dragStart: e.screenX,
      dragStartIndex: images.startIndex,
    });
  };

  const updateImageIndex = (currentPosition) => {
    // console.log('update image index');
    // console.log(currentPosition);
    let numImages = imageList.length;
    const pixelsPerImage = pixelsPerDegree * (360 / numImages);

    const { startIndex, dragStart, dragStartIndex } = images;
    // pixels moved
    let dx = (currentPosition - dragStart) / pixelsPerImage;
    let index = Math.floor(dx) % numImages;
    // decide whether to change the order
    index = scanSwitch ? index * -1 : index;
    // index calculate
    if (index < 0) index = numImages + index - 1;
    index = (index + dragStartIndex) % numImages;

    if (index !== startIndex) {
      setImages({
        ...images,
        startIndex: index,
      });
    }
  };

  const handleMouseMove = (e) => {
    // console.log('mouese move 事件');
    if (images.dragging) {
      // console.log('dragging');
      updateImageIndex(e.screenX);
    }
  };

  const handleMouseUp = () => {
    // console.log('mouse up 事件');
    setImages({
      ...images,
      dragging: false,
    });
  };

  const handleDragStart = (e) => {
    // console.log('drag start 事件');
    e.preventDefault();
  };

  useEffect(() => {
    const imgID = document.querySelectorAll(".img360");

    imgID.forEach((item, index) => {
      const img = new Image();
      img.src = item.src;
      // img.addEventListener('load', () => console.log(`圖片載入完成${index}`));
    });
  }, []);

  useEffect(() => {
    const imgContainerID = document.querySelectorAll(".imgContainer");

    imgContainerID.forEach((item, index) => {
      if (index === images.startIndex) {
        item.style.display = "flex";
      } else {
        item.style.display = "none";
      }
    });
  }, [images.startIndex]);

  // 以下 touch 事件為手機裝置專用
  const handleTouchStart = (e) => {
    // console.log('touch start 事件');
    // console.log(e.targetTouches[0].screenX);
    if (gestureElement.current.style.display !== "none") {
      gestureElement.current.style.display = "none";
    }
    // 關閉所有標籤面板
    if (viewer || (tagNavbar && tagSwitch)) setDisplayPanel(null);
    setImages({
      ...images,
      dragging: true,
      dragStart: e.targetTouches[0].screenX,
      dragStartIndex: images.startIndex,
    });
  };

  const handleTouchMove = (e) => {
    // console.log('touch move 事件');
    if (images.dragging) {
      // console.log('dragging');
      updateImageIndex(e.targetTouches[0].screenX);
    }
  };

  const handleTouchEnd = (e) => {
    // console.log('touch end 事件');
    // 使用 preventDefault() 停止 mouse event 的派送
    e.preventDefault();
    setImages({
      ...images,
      dragging: false,
    });
  };

  const handleDoubleClick = (e, index) => {
    // 關閉顯示標籤＆不是標籤列表時，不觸發「新增標籤」功能
    if (!tagSwitch) return;
    if (!tagNavbar) return;

    const left = ((e.nativeEvent.offsetX - 12) / e.target.width) * 100;
    const top = ((e.nativeEvent.offsetY - 12) / e.target.height) * 100;

    const createNewTag = {
      id: null,
      uniqueId: tags.length + 1,
      dataPosition: `${left} ${top}`,
      scankitIndex: index,
      title: "",
      content: "",
      icon: "",
      url: "",
    };

    // dispatch newTags 就會更新 setTags
    dispatch({ type: SET_NEW_TAG, payload: createNewTag });
  };

  useEffect(() => {
    // 關閉顯示標籤＆不是標籤列表時，不觸發「標籤快捷鍵」功能
    if (!tagSwitch) return;
    if (!tagNavbar) return;

    // 點擊標籤列表裡的標籤，跳到該標籤的 imageIndex
    setImages({
      ...images,
      startIndex: currentTag.imageIndex,
    });

    // 點擊標籤列表裡的標籤，如該標籤面板已開啟則收合
    if (currentTag.serialNumber === displayPanel) {
      setDisplayPanel(null);
    }

    // 點擊標籤列表裡的標籤，如該標籤面板未開啟則展開
    if (currentTag.serialNumber !== displayPanel) {
      setDisplayPanel(currentTag.serialNumber);
    }
  }, [currentTag]);

  return (
    <StyledImage360
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onDragStart={handleDragStart}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="allContainer">
        {imageList.map((item, index) => {
          return (
            <div key={index} className="imgContainer">
              <img
                src={item}
                alt="images"
                className="img360"
                style={{ transform: `scale(${rate})` }}
                onDoubleClick={(e) => handleDoubleClick(e, index)}
              />
            </div>
          );
        })}
        {/* Editor 才顯示 */}
        {tagNavbar &&
          tags.map((item, index) => {
            return (
              <div key={index} className="tagContainer">
                <ScanEditorTag
                  item={item}
                  index={index}
                  images={images}
                  newTag={item.id === null}
                  currentTag={currentTag}
                  displayPanel={displayPanel}
                  setDisplayPanel={setDisplayPanel}
                />
              </div>
            );
          })}
        {/* Viewer 才顯示 */}
        {viewer &&
          tags.map((item, index) => {
            return (
              <div key={index} className="tagContainer">
                <ScanViewerTag
                  item={item}
                  index={index}
                  images={images}
                  displayPanel={displayPanel}
                  setDisplayPanel={setDisplayPanel}
                />
              </div>
            );
          })}
      </div>
      {imageList.length > 0 && <Gesture ref={gestureElement} className="gestureIcon" />}
    </StyledImage360>
  );
};

export default Image360;
