import axiosApi from "api/root.api";

export const getCode = async (projectId) => {
  const urlEnd = "/editor/project/v1/objectCode";
  const response = await axiosApi.get(urlEnd, { params: { projectId } });
  return response.data;
};
export const updateCode = async (projectId) => {
  const token = axiosApi.defaults.headers.common.Authorization;
  const urlEnd = `/editor/project/v1/${projectId}/objectCode`;
  const response = await axiosApi.put(urlEnd, {}, { headers: { Authorization: token } });
  return response.data;
};
