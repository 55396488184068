import styled from "styled-components";

export const StyledScanKit = styled.div`
  width: 100%;
  height: 100vh;

  .contentContainer {
    width: 100%;
    height: calc(100% - 160px);
    overflow: auto;
  }

  .btnContainer {
    margin: 48px 0px;
    width: 100%;
    height: 50px;
    padding-right: 80px;
  }

  .cardListPanel {
    width: 100%;
    display: grid;
    padding-right: 80px;
    padding-left: 80px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    margin: 0 auto;
    padding-bottom: 30px;
    @media all and (max-width: 1600px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media all and (max-width: 1270px) {
      grid-template-columns: 1fr 1fr;
    }
    > * {
      min-width: 0;
    }
  }

  .card {
    height: 320px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      .cardImagePanel::before {
        display: block;
      }
    }
  }

  .cardImagePanel {
    position: relative;
    width: 100%;
    height: 240px;
    background: #cccccc;
    border-radius: 8px 8px 0 0;
    position: relative;
    border-bottom: 1px solid #cccccc;

    .cardSettingBtn {
      position: absolute;
      top: 12px;
      left: 12px;
      width: 24px;
      height: 24px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 2px;
      cursor: pointer;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }
    }

    .draftTag {
      position: absolute;
      top: 12px;
      right: 12px;
      padding: 1px 16px;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 24px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #ffffff;
    }

    .isPublish {
      background: #2bb8af;
    }

    .scankitIcon {
      position: absolute;
      bottom: 16px;
      left: 16px;
      width: 32px;
      height: 32px;
    }

    .cardSettingPanel {
      position: absolute;
      top: 12px;
      left: 40px;
      width: 178px;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      text-align: left;
      padding: 4px 0px;
      overflow: hidden;
      z-index: 10;
    }

    .cardImage {
      border-radius: 8px 8px 0 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .cardImagePanel::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 8px 8px 0 0;
    display: none;
  }

  .cardInfoPanel {
    width: 100%;
    height: 80px;
    text-align: left;
    padding: 0 20px;

    .cardName {
      display: block;
      width: calc(100% - 70px);
      .nn {
        width: 270px;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        white-space: nowrap;
      }

      .visitCount {
        display: flex;
        column-gap: 4px;
        align-items: center;
        color: #bfbfbf;
        margin-top: 8px;
        > svg {
          width: 18px;
          height: 18px;
          padding: 2px 1px;
        }
        > span {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.02em;
        }
      }
    }

    .cardEditBtn {
      grid-row: 1 / 3;
      background: #ff7043;
      border-radius: 4px;
      width: 77px;
      height: 40px;
      color: #fff;
      align-self: center;
      justify-self: center;
      cursor: pointer;
      &:hover {
        background: #ffa270;
      }
    }
  }

  .emptyContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .imageWrapper {
      margin-bottom: 64px;
      > img {
        width: 282px;
        height: 232px;
      }
      > div {
        font-size: 16px;
        line-height: 24px;
        color: #8c8c8c;
      }
    }

    .buttonWrapper {
      display: flex;
      justify-content: center;
    }
  }
`;
