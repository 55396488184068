// styled components
import { StyledNotice } from "./StyledNotice";

// react icon
import { AiFillCheckCircle } from "react-icons/ai";

const Notice = ({ text }) => {
  return (
    <StyledNotice>
      <AiFillCheckCircle />
      <span>{text}</span>
    </StyledNotice>
  );
};

export default Notice;
