import styled from "styled-components";

export const StyledUpgradeCon = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100vh;
  background: #f2f2f2;
`;
