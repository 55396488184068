import { useEffect, useState } from "react";

// styled components
import { StyledBanner } from "./StyledBanner";

// react-icon
import { IoCloseCircleOutline } from "react-icons/io5";

// i18n
import { useTranslation } from "react-i18next";

// plugin
import Cookies from "js-cookie";
import axios from "axios";

// constants
import SERVER from "constants/server";

const Banner = ({ setBanner }) => {
  const { t } = useTranslation();

  const handleCloseBanner = (e) => {
    e.preventDefault();
    setBanner(false);
  };

  const [bannerData, setBannerData] = useState({
    language: "",
    bannerUrl: "",
    activityUrl: "#",
  });

  useEffect(() => {
    // console.log('banner: ' + Cookies.get('lang'));
    const fetchBannerData = async () => {
      const response = await axios({
        method: "get",
        url: `${SERVER.PLATFORM_URL}/public/v1/activityBanner/AM?lang=${Cookies.get("lang")}`,
      });
      // console.log(response.data[0]);
      setBannerData(response.data[0]);
    };

    fetchBannerData();
  }, [Cookies.get("lang")]);

  return (
    <StyledBanner>
      {bannerData !== undefined && (
        <a
          className="banner"
          href={bannerData.activityUrl}
          target="_blank"
          rel="noreferrer noopenner"
        >
          <IoCloseCircleOutline className="banner_close_button" onClick={handleCloseBanner} />
          {bannerData.bannerUrl !== "" && (
            <img src={bannerData.bannerUrl} className="banner_image" alt="banner-image" />
          )}
        </a>
      )}
    </StyledBanner>
  );
};

export default Banner;
