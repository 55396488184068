import styled from "styled-components";

export const StyledModalPopupContainer = styled.div`
  position: ${(props) => (props.iframe ? "absolute" : "fixed")};
  top: 0;
  left: 0;
  width: ${(props) => (props.iframe ? "100%" : "100vw")};
  height: ${(props) => (props.iframe ? "100%" : "100vh")};
  z-index: ${(props) => props.theme.zIndex.LayerTen};
  background: ${(props) => (props.coverSetting ? "#fff" : "rgba(0,0,0,0.8)")};
  margin-top: ${(props) => (props.mode === "cardList" ? "160px" : "0px")};
  position: ${(props) => (props.iframe ? "absolute" : "fixed")};
  width: ${(props) => (props.iframe ? "100%" : "100vw")};
  height: ${(props) => (props.iframe ? "100%" : "100vh")};
  .copySuccess {
    width: 230px;
    background: #54c68f;
    border-radius: 4px;
    padding: 8px 24px;
    color: #ffffff;
    line-height: 140%;
    position: absolute;
    top: calc((100vh - 488px) / 2);
    left: calc((100vw - 230px) / 2);
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }
  .flexReverseRow {
    flex-direction: row-reverse;
  }

  .modalPanel {
    width: 560px;
    min-height: 360px;
    background: #ffffff;
    border-radius: 12px;
    @media screen and (max-width: 768px) {
      width: 454px;
    }
    @media screen and (max-width: 414px) {
      width: 366px;
    }
  }

  .topSection {
    padding: 25px 15px;
    width: 100%;
    height: 48px;
    border-radius: 12px 12px 0px 0px;
    svg {
      font-size: 30px;
      fill: #222;
      &:hover {
        cursor: pointer;
      }
    }
    @media screen and (max-width: 414px) {
      padding: 10px;
      svg {
        font-size: 24px;
      }
    }
  }

  .bottomSection {
    padding: 10px 32px 32px 32px;
    width: 100%;
    height: calc(100% - 48px);
    align-content: center;
    gap: 36px;
    @media screen and (max-width: 414px) {
      padding: 10px 24px 32px 24px;
    }
  }

  .iconPanel {
    width: 100%;

    img {
      width: 72px;
      height: 72px;
    }
    @media screen and (max-width: 414px) {
      img {
        width: 43px;
        height: 43px;
      }
    }
  }

  .descPanel {
    margin-bottom: 4px;
    color: #333;
    font-weight: 500;
    font-size: 15px;
    width: 100%;
    text-align: center;
    span {
      color: #f34f66;
    }
    @media screen and (max-width: 414px) {
      font-size: 14px;
    }
  }
    
  .noticePanel {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    font-size: 13px;
    width: 100%;
    text-align: center;
    span {
      color: #f34f66;
    }
    @media screen and (max-width: 414px) {
      margin-bottom: 40px;
      font-size: 12px;
    }
  }

  .cancelButton {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.05);
    order: ${(props) =>
            ["file-save", "file-preview", "file-copy", "file-transfer", "alert-transfer"].includes(props.type) ? 1 : 2};
    padding: 8px 24px;
    line-height: 24px;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.12);
    }
  }
  .confirmButtons {
    min-width: 250px;
    max-width: 350px;
    gap: 64px;
  }
  .confirmButton {
    background: ${(props) =>
      props.type === "delete" ? "#F5314D" : props.type === "file-delete" ? "#F5314D" : "#27282C"};
    order: ${(props) =>
            ["file-save", "file-preview", "file-copy", "file-transfer", "alert-transfer"].includes(props.type) ? 2 : 1};
    border-radius: 8px;
    color: #ffffff;
    padding: 8px 24px;
    line-height: 24px;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background: ${(props) =>
        props.type === "delete" ? "#F34F66" : props.type === "file-delete" ? "#F34F66" : "#47484B"};
    }
  }

  .okButton {
    background: #f5314d;
    border-radius: 4px;
    padding: 12px 24px;
    color: #fff;
    cursor: pointer;
    &:hover {
      background: #f2539d;
    }
  }
  .shareOnly {
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      width: 100%;
      border: 1px solid #bbb;
      border-radius: 4px;
      padding: 0 5px;
      height: 30px;
      vertical-align: middle;
      &:focus {
        outline-color: #ff744e;
        outline-width: 1px;
      }
    }
    .iconCon {
      margin-left: 10px;
      cursor: pointer;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: #ed0973;
      &:hover {
        background-color: #f2539d;
      }
    }
  }
    .transfer{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
        .transfer-label {
            text-align: left;
            font-size: 16px;
            width: 100%;
        }
        .transfer-input-wrapper{
            border: 1px solid;
            border-color: rgba(0,0,0,0.12);
            border-radius: 4px;
            input {
                padding: 8px 16px;
                font-size: 16px;
                width: 100%;
            }
            input:focus {
                outline: none;
            }
        }
        .transfer-input-error-message{
            font-size: 14px;
            color: red;
            text-align: left;
            padding: 6px 4px;
        }
    }  
`;
